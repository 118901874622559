/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const advancedSearchGRC = /* GraphQL */ `
  mutation AdvancedSearchGRC($searchCriteria: SearchCriteria!, $offset: Int) {
    advancedSearchGRC(searchCriteria: $searchCriteria, offset: $offset) {
      data {
        grcRecipeID
        title
        imageUrl
        mealTypes
        score
      }
      nextOffset
      moreAvailable
      estimatedTotalResults
    }
  }
`;
export const advancedSearchOwnMeals = /* GraphQL */ `
  mutation AdvancedSearchOwnMeals(
    $searchCriteria: SearchCriteria!
    $offset: Int
  ) {
    advancedSearchOwnMeals(searchCriteria: $searchCriteria, offset: $offset) {
      data {
        mealID
        parentID
        title
        imageUrl
        mealTypes
        score
      }
      nextOffset
      moreAvailable
      estimatedTotalResults
    }
  }
`;
export const getGRCRecipeCategories = /* GraphQL */ `
  mutation GetGRCRecipeCategories($spaceID: String!) {
    getGRCRecipeCategories(spaceID: $spaceID) {
      id
      title
    }
  }
`;
export const getGRCResults = /* GraphQL */ `
  mutation GetGRCResults(
    $spaceID: String!
    $grcRecipeCategoryID: String!
    $offset: Int
  ) {
    getGRCResults(
      spaceID: $spaceID
      grcRecipeCategoryID: $grcRecipeCategoryID
      offset: $offset
    ) {
      data {
        grcRecipeID
        title
        imageUrl
        mealTypes
        score
      }
      nextOffset
      moreAvailable
      estimatedTotalResults
    }
  }
`;
export const addRecipe = /* GraphQL */ `
  mutation AddRecipe($url: String!) {
    addRecipe(url: $url) {
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      error
    }
  }
`;
export const addRecipesBulk = /* GraphQL */ `
  mutation AddRecipesBulk($ldjsonListStr: String!) {
    addRecipesBulk(ldjsonListStr: $ldjsonListStr) {
      title
      shortDescription
      imageUrl
      recipeUrl
      publisher
      chef
      mealiqId
      nutrition {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      mealTypes
      tags
      supportedDiets
      ingredients {
        id
        fullText
        structuredIngredient {
          name
          quantity
          unitOfMeasure
          normalisedQuantity
          linkedIngredient {
            status
            linkedIngredientName
          }
          resolvedNutrition
          resolvedNutritionError
        }
        tokFullText
        tokens {
          fromChar
          toChar
          type
          label
        }
        scalingRules
        checkedInIngredientsList
      }
      preparations {
        id
        fullText
      }
      utensils {
        id
        fullText
      }
      servings
      cookTime
      prepTime
      totalTime
      recipeNotes
    }
  }
`;
export const analyzeIngredients = /* GraphQL */ `
  mutation AnalyzeIngredients(
    $ingsFullText: [String!]!
    $customerFoodDataID: String
  ) {
    analyzeIngredients(
      ingsFullText: $ingsFullText
      customerFoodDataID: $customerFoodDataID
    ) {
      id
      fullText
      structuredIngredient {
        name
        quantity
        unitOfMeasure
        normalisedQuantity
        linkedIngredient {
          status
          linkedIngredientName
        }
        resolvedNutrition
        resolvedNutritionError
      }
      tokFullText
      tokens {
        fromChar
        toChar
        type
        label
      }
      scalingRules
      checkedInIngredientsList
    }
  }
`;
export const deriveNutrition = /* GraphQL */ `
  mutation DeriveNutrition(
    $servings: Float!
    $structuredIngredients: [DerivedNutritionStructuredIngredientInput!]!
    $publisher: String
    $chef: String
    $customerFoodDataID: String
  ) {
    deriveNutrition(
      servings: $servings
      structuredIngredients: $structuredIngredients
      publisher: $publisher
      chef: $chef
      customerFoodDataID: $customerFoodDataID
    ) {
      totalNutritionPerServing {
        calories
        protein
        carbohydrate
        fat
        fibre
        sugar
        saturates
        salt
        veg_weight
        cholesterol
        calcium
        iron
        magnesium
        potassium
        vitamin_a
        vitamin_b1
        vitamin_b2
        vitamin_b3
        vitamin_b5
        vitamin_b6
        vitamin_b12
        vitamin_c
        vitamin_d
        vitamin_e
        vitamin_k
        phosphorous
        zinc
        copper
        manganese
        selenium
        folate
        omega3_dha
        omega3_dpa
        omega3_epa
        sucrose
        glucose
        fructose
        lactose
        maltose
      }
      ingredientNutrition {
        ingredientID
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        resolvedNutrition
        error
        resolvedIngredientWeight
      }
      foodAllergens
      foodPreferences
      status
      macroRatio {
        carbohydrate
        protein
        fat
      }
    }
  }
`;
export const deriveNutritionMeals = /* GraphQL */ `
  mutation DeriveNutritionMeals(
    $meals: [DerivedNutritionMealInput!]!
    $customerFoodDataID: String
  ) {
    deriveNutritionMeals(
      meals: $meals
      customerFoodDataID: $customerFoodDataID
    ) {
      mealID
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
    }
  }
`;
export const compileIngredientCatalog = /* GraphQL */ `
  mutation CompileIngredientCatalog(
    $menu: [RecommenderMealInput!]!
    $previousIngredientCatalog: [PreviousShoppingListItemInput!]
    $locale: String
  ) {
    compileIngredientCatalog(
      menu: $menu
      previousIngredientCatalog: $previousIngredientCatalog
      locale: $locale
    ) {
      id
      name
      total {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      quantities {
        quantity
        prettyQuantity
        unitOfMeasure
      }
      linkedIngredient {
        status
        linkedIngredientName
      }
      aisleLocation
      forMealIds
      checked
    }
  }
`;
export const getSuggestions = /* GraphQL */ `
  mutation GetSuggestions(
    $searches: [IngredientSuggestionSearchInput!]!
    $locale: String
  ) {
    getSuggestions(searches: $searches, locale: $locale) {
      suggestions {
        suggestionText
        suggestionType
        highlightTokens {
          fromChar
          toChar
        }
      }
    }
  }
`;
export const scaleIngredients = /* GraphQL */ `
  mutation ScaleIngredients(
    $scaleFactor: Float!
    $ingredients: [IngredientScaleInput!]!
  ) {
    scaleIngredients(scaleFactor: $scaleFactor, ingredients: $ingredients) {
      id
      fullText
      structuredIngredient {
        name
        quantity
        unitOfMeasure
        normalisedQuantity
        linkedIngredient {
          status
          linkedIngredientName
        }
        resolvedNutrition
        resolvedNutritionError
      }
      tokFullText
      tokens {
        fromChar
        toChar
        type
        label
      }
      scalingRules
      checkedInIngredientsList
    }
  }
`;
export const localiseIngredients = /* GraphQL */ `
  mutation LocaliseIngredients(
    $ingredients: [IngredientSuggestionSearchInput!]!
    $localiseWhat: String!
    $targetLocale: String!
  ) {
    localiseIngredients(
      ingredients: $ingredients
      localiseWhat: $localiseWhat
      targetLocale: $targetLocale
    ) {
      id
      fullText
      structuredIngredient {
        name
        quantity
        unitOfMeasure
        normalisedQuantity
        linkedIngredient {
          status
          linkedIngredientName
        }
        resolvedNutrition
        resolvedNutritionError
      }
      tokFullText
      tokens {
        fromChar
        toChar
        type
        label
      }
      scalingRules
      checkedInIngredientsList
    }
  }
`;
export const updateContactAttribute = /* GraphQL */ `
  mutation UpdateContactAttribute(
    $email: AWSEmail!
    $attributeName: String!
    $attributeValue: String
  ) {
    updateContactAttribute(
      email: $email
      attributeName: $attributeName
      attributeValue: $attributeValue
    )
  }
`;
export const emailShoppingList = /* GraphQL */ `
  mutation EmailShoppingList(
    $shoppingList: EmailShoppingListInput!
    $recipient: AWSEmail!
    $senderName: String
  ) {
    emailShoppingList(
      shoppingList: $shoppingList
      recipient: $recipient
      senderName: $senderName
    )
  }
`;
export const emailSpaceUrlToSelf = /* GraphQL */ `
  mutation EmailSpaceUrlToSelf($spaceUrl: String!) {
    emailSpaceUrlToSelf(spaceUrl: $spaceUrl)
  }
`;
export const adminEmailUserRequestedFeature = /* GraphQL */ `
  mutation AdminEmailUserRequestedFeature(
    $userId: String!
    $userEmail: AWSEmail!
    $featureCode: String!
  ) {
    adminEmailUserRequestedFeature(
      userId: $userId
      userEmail: $userEmail
      featureCode: $featureCode
    )
  }
`;
export const adminEmailUserDeleted = /* GraphQL */ `
  mutation AdminEmailUserDeleted($userId: String!, $userEmail: AWSEmail!) {
    adminEmailUserDeleted(userId: $userId, userEmail: $userEmail)
  }
`;
export const getMixpanelUserGeolocation = /* GraphQL */ `
  mutation GetMixpanelUserGeolocation($mixpanelDistinctId: String!) {
    getMixpanelUserGeolocation(mixpanelDistinctId: $mixpanelDistinctId) {
      countryCode
    }
  }
`;
export const generateRecipeAI = /* GraphQL */ `
  mutation GenerateRecipeAI($prompt: String!) {
    generateRecipeAI(prompt: $prompt) {
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      error
    }
  }
`;
export const beginGenerateRecipeImageAI = /* GraphQL */ `
  mutation BeginGenerateRecipeImageAI($prompt: String!, $size: String!) {
    beginGenerateRecipeImageAI(prompt: $prompt, size: $size)
  }
`;
export const checkGenerateRecipeImageAI = /* GraphQL */ `
  mutation CheckGenerateRecipeImageAI($jobID: ID!) {
    checkGenerateRecipeImageAI(jobID: $jobID) {
      jobID
      jobStatus
      imageUrl
    }
  }
`;
export const beginGenerateRecipeAI = /* GraphQL */ `
  mutation BeginGenerateRecipeAI($prompt: String!) {
    beginGenerateRecipeAI(prompt: $prompt)
  }
`;
export const checkGenerateRecipeAI = /* GraphQL */ `
  mutation CheckGenerateRecipeAI($jobID: ID!) {
    checkGenerateRecipeAI(jobID: $jobID) {
      jobID
      jobStatus
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
    }
  }
`;
export const beginModerateRecipeAI = /* GraphQL */ `
  mutation BeginModerateRecipeAI(
    $ingredients: [String!]
    $preparations: [String!]!
  ) {
    beginModerateRecipeAI(
      ingredients: $ingredients
      preparations: $preparations
    )
  }
`;
export const checkModerateRecipeAI = /* GraphQL */ `
  mutation CheckModerateRecipeAI($jobID: ID!) {
    checkModerateRecipeAI(jobID: $jobID) {
      jobID
      jobStatus
      moderatedIngredients
      moderatedPreparations
    }
  }
`;
export const pingRecipesService = /* GraphQL */ `
  mutation PingRecipesService {
    pingRecipesService {
      overallStatus
      publicIp
    }
  }
`;
export const reportAccountActivitySignal = /* GraphQL */ `
  mutation ReportAccountActivitySignal(
    $accountActivitySignal: AccountActivitySignalReport!
  ) {
    reportAccountActivitySignal(accountActivitySignal: $accountActivitySignal) {
      overallStatus
      publicIp
    }
  }
`;
export const createMySpaceMembership = /* GraphQL */ `
  mutation CreateMySpaceMembership(
    $spaceID: ID!
    $isPreactivated: Boolean
    $onboardingAnswers: [OnboardingAnswerInput!]
    $onboardingAnswersLastUpdatedAt: AWSDateTime
    $referrer: SpaceMembershipReferrer
  ) {
    createMySpaceMembership(
      spaceID: $spaceID
      isPreactivated: $isPreactivated
      onboardingAnswers: $onboardingAnswers
      onboardingAnswersLastUpdatedAt: $onboardingAnswersLastUpdatedAt
      referrer: $referrer
    ) {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateMembershipWithAppleIAP = /* GraphQL */ `
  mutation UpdateMembershipWithAppleIAP(
    $spaceMembershipID: ID!
    $appStoreTransactionReceipt: String!
  ) {
    updateMembershipWithAppleIAP(
      spaceMembershipID: $spaceMembershipID
      appStoreTransactionReceipt: $appStoreTransactionReceipt
    ) {
      spaceMembershipID
      appStoreTransactionReceipt
      result
      membershipExpiresAt
    }
  }
`;
export const updateMembershipWithGooglePlayPurchase = /* GraphQL */ `
  mutation UpdateMembershipWithGooglePlayPurchase(
    $spaceMembershipID: ID!
    $googlePlayTransactionReceipt: String!
  ) {
    updateMembershipWithGooglePlayPurchase(
      spaceMembershipID: $spaceMembershipID
      googlePlayTransactionReceipt: $googlePlayTransactionReceipt
    ) {
      spaceMembershipID
      appStoreTransactionReceipt
      result
      membershipExpiresAt
    }
  }
`;
export const updateMembershipNoTierAvailable = /* GraphQL */ `
  mutation UpdateMembershipNoTierAvailable($spaceMembershipID: ID!) {
    updateMembershipNoTierAvailable(spaceMembershipID: $spaceMembershipID) {
      spaceMembershipID
      appStoreTransactionReceipt
      result
      membershipExpiresAt
    }
  }
`;
export const syncSpaceMembership = /* GraphQL */ `
  mutation SyncSpaceMembership(
    $spaceMembershipID: ID!
    $onboardingAnswers: [OnboardingAnswerInput!]!
    $onboardingAnswersLastUpdatedAt: AWSDateTime!
    $customTargetCalories: Float
    $welcomeVideoWatched: Boolean
    $initialProgrammeSelectionLastOfferedAt: AWSDateTime
    $chatNickname: String
  ) {
    syncSpaceMembership(
      spaceMembershipID: $spaceMembershipID
      onboardingAnswers: $onboardingAnswers
      onboardingAnswersLastUpdatedAt: $onboardingAnswersLastUpdatedAt
      customTargetCalories: $customTargetCalories
      welcomeVideoWatched: $welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt: $initialProgrammeSelectionLastOfferedAt
      chatNickname: $chatNickname
    ) {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
  }
`;
export const recommendMeals = /* GraphQL */ `
  mutation RecommendMeals(
    $menuRecommendationRequest: MenuRecommendationRequest!
  ) {
    recommendMeals(menuRecommendationRequest: $menuRecommendationRequest) {
      days {
        dayIndex
        entries {
          entryType
          mealType
          meals {
            mealSource
            id
            servings
            categoryTags
            scaleFactor
            scaledIngredientsFullText
            scaledNutrition {
              calories
              protein
              carbohydrate
              fat
              fibre
              sugar
              saturates
              salt
              veg_weight
              cholesterol
              calcium
              iron
              magnesium
              potassium
              vitamin_a
              vitamin_b1
              vitamin_b2
              vitamin_b3
              vitamin_b5
              vitamin_b6
              vitamin_b12
              vitamin_c
              vitamin_d
              vitamin_e
              vitamin_k
              phosphorous
              zinc
              copper
              manganese
              selenium
              folate
              omega3_dha
              omega3_dpa
              omega3_epa
              sucrose
              glucose
              fructose
              lactose
              maltose
            }
            scaledStructuredIngredients {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            scaledDerivedIngredientNutrition {
              ingredientID
              nutrition {
                calories
                protein
                carbohydrate
                fat
                fibre
                sugar
                saturates
                salt
                veg_weight
                cholesterol
                calcium
                iron
                magnesium
                potassium
                vitamin_a
                vitamin_b1
                vitamin_b2
                vitamin_b3
                vitamin_b5
                vitamin_b6
                vitamin_b12
                vitamin_c
                vitamin_d
                vitamin_e
                vitamin_k
                phosphorous
                zinc
                copper
                manganese
                selenium
                folate
                omega3_dha
                omega3_dpa
                omega3_epa
                sucrose
                glucose
                fructose
                lactose
                maltose
              }
              resolvedNutrition
              error
              resolvedIngredientWeight
            }
          }
        }
      }
      errorMessage
    }
  }
`;
export const expandIngredientsForExclusion = /* GraphQL */ `
  mutation ExpandIngredientsForExclusion(
    $rootIngredientNames: [String!]!
    $locales: [String!]
  ) {
    expandIngredientsForExclusion(
      rootIngredientNames: $rootIngredientNames
      locales: $locales
    )
  }
`;
export const getNewStripeConfig = /* GraphQL */ `
  mutation GetNewStripeConfig($authorizationCode: String!) {
    getNewStripeConfig(authorizationCode: $authorizationCode) {
      environment
      connectedAccountID
      chargesEnabled
      stripeProductID
    }
  }
`;
export const createStripeCheckoutSession = /* GraphQL */ `
  mutation CreateStripeCheckoutSession(
    $membershipTierID: ID!
    $spaceMembershipID: ID!
    $returnUrl: String!
    $duration: SubscriptionDuration
  ) {
    createStripeCheckoutSession(
      membershipTierID: $membershipTierID
      spaceMembershipID: $spaceMembershipID
      returnUrl: $returnUrl
      duration: $duration
    ) {
      url
    }
  }
`;
export const checkStripeCheckoutSession = /* GraphQL */ `
  mutation CheckStripeCheckoutSession($spaceMembershipID: ID!) {
    checkStripeCheckoutSession(spaceMembershipID: $spaceMembershipID)
  }
`;
export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($spaceMembershipID: ID!) {
    cancelSubscription(spaceMembershipID: $spaceMembershipID)
  }
`;
export const activateMembershipTier = /* GraphQL */ `
  mutation ActivateMembershipTier($membershipTierID: ID!) {
    activateMembershipTier(membershipTierID: $membershipTierID) {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listYoutubePlaylistItems = /* GraphQL */ `
  mutation ListYoutubePlaylistItems($playlistID: ID!) {
    listYoutubePlaylistItems(playlistID: $playlistID) {
      videoID
      title
      description
      thumbnailImageUrl
    }
  }
`;
export const enrollUserIntoChat = /* GraphQL */ `
  mutation EnrollUserIntoChat($spaceMembershipID: ID!, $userName: String!) {
    enrollUserIntoChat(
      spaceMembershipID: $spaceMembershipID
      userName: $userName
    ) {
      status
      authToken
    }
  }
`;
export const logInUserToChat = /* GraphQL */ `
  mutation LogInUserToChat($spaceMembershipID: ID!) {
    logInUserToChat(spaceMembershipID: $spaceMembershipID) {
      authToken
    }
  }
`;
export const adminListChatGroups = /* GraphQL */ `
  mutation AdminListChatGroups {
    adminListChatGroups {
      guid
      name
    }
  }
`;
export const listSmorgStudioMemberships = /* GraphQL */ `
  mutation ListSmorgStudioMemberships {
    listSmorgStudioMemberships {
      id
      createdAt
      spaceID
      memberID
      email
      givenName
      familyName
      state
      correlatedUserID
      expiresAt
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
    }
  }
`;
export const smorgStudioMembershipAdminSetExpiresAt = /* GraphQL */ `
  mutation SmorgStudioMembershipAdminSetExpiresAt(
    $spaceMembershipID: ID!
    $expiresAt: AWSDateTime!
  ) {
    smorgStudioMembershipAdminSetExpiresAt(
      spaceMembershipID: $spaceMembershipID
      expiresAt: $expiresAt
    ) {
      id
      createdAt
      spaceID
      memberID
      email
      givenName
      familyName
      state
      correlatedUserID
      expiresAt
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
    }
  }
`;
export const smorgStudioMembershipAdminSetPassword = /* GraphQL */ `
  mutation SmorgStudioMembershipAdminSetPassword(
    $spaceMembershipID: ID!
    $cleartextPassword: String!
  ) {
    smorgStudioMembershipAdminSetPassword(
      spaceMembershipID: $spaceMembershipID
      cleartextPassword: $cleartextPassword
    ) {
      success
      message
    }
  }
`;
export const createZapierRestHook = /* GraphQL */ `
  mutation CreateZapierRestHook(
    $input: CreateZapierRestHookInput!
    $condition: ModelZapierRestHookConditionInput
  ) {
    createZapierRestHook(input: $input, condition: $condition) {
      spaceID
      hookUrl
      hookKey
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateZapierRestHook = /* GraphQL */ `
  mutation UpdateZapierRestHook(
    $input: UpdateZapierRestHookInput!
    $condition: ModelZapierRestHookConditionInput
  ) {
    updateZapierRestHook(input: $input, condition: $condition) {
      spaceID
      hookUrl
      hookKey
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteZapierRestHook = /* GraphQL */ `
  mutation DeleteZapierRestHook(
    $input: DeleteZapierRestHookInput!
    $condition: ModelZapierRestHookConditionInput
  ) {
    deleteZapierRestHook(input: $input, condition: $condition) {
      spaceID
      hookUrl
      hookKey
      enabled
      id
      createdAt
      updatedAt
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      showOnboarding
      consentToProductComms
      plannerViewWeekStartDay
      geoRegion
      countryCode
      mealPlanReminderSchedule
      showMealDetailGuide
      recentlyUsedTags
      activitySummary {
        numMealsViewed
        numMealsViewedMobile
        numShoppingListsViewed
        numShoppingListsViewedMobile
        numMealsEdited
        numNutritionClicks
        numMealsPlanned
        numMealsAdded
        numMealsImported
        numMealsAddedManually
        numPlansViewed
        numShoppingListsCopiedToClipboard
        numShoppingListsEmailed
        numShoppingListsCreated
        numShops
        numNotesAdded
        numNotesViewed
        numNotesViewedMobile
        numRecipesBoardsCreated
        numRecipesBoardsShared
      }
      enabledFeatures
      requestedFeatures
      seenMessages
      recentlyUsedRecommenderMealTypes
      recentlyUsedRecommenderCalorieSplits {
        mealType
        fraction
      }
      recentlyUsedSearchCriteria {
        searchString
        mealTypes
        cookTimeMin
        cookTimeMax
        excludeIngredients
        includeIngredients
        dietaryPreferences
        perMealNutritionConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        tags
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      showOnboarding
      consentToProductComms
      plannerViewWeekStartDay
      geoRegion
      countryCode
      mealPlanReminderSchedule
      showMealDetailGuide
      recentlyUsedTags
      activitySummary {
        numMealsViewed
        numMealsViewedMobile
        numShoppingListsViewed
        numShoppingListsViewedMobile
        numMealsEdited
        numNutritionClicks
        numMealsPlanned
        numMealsAdded
        numMealsImported
        numMealsAddedManually
        numPlansViewed
        numShoppingListsCopiedToClipboard
        numShoppingListsEmailed
        numShoppingListsCreated
        numShops
        numNotesAdded
        numNotesViewed
        numNotesViewedMobile
        numRecipesBoardsCreated
        numRecipesBoardsShared
      }
      enabledFeatures
      requestedFeatures
      seenMessages
      recentlyUsedRecommenderMealTypes
      recentlyUsedRecommenderCalorieSplits {
        mealType
        fraction
      }
      recentlyUsedSearchCriteria {
        searchString
        mealTypes
        cookTimeMin
        cookTimeMax
        excludeIngredients
        includeIngredients
        dietaryPreferences
        perMealNutritionConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        tags
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      showOnboarding
      consentToProductComms
      plannerViewWeekStartDay
      geoRegion
      countryCode
      mealPlanReminderSchedule
      showMealDetailGuide
      recentlyUsedTags
      activitySummary {
        numMealsViewed
        numMealsViewedMobile
        numShoppingListsViewed
        numShoppingListsViewedMobile
        numMealsEdited
        numNutritionClicks
        numMealsPlanned
        numMealsAdded
        numMealsImported
        numMealsAddedManually
        numPlansViewed
        numShoppingListsCopiedToClipboard
        numShoppingListsEmailed
        numShoppingListsCreated
        numShops
        numNotesAdded
        numNotesViewed
        numNotesViewedMobile
        numRecipesBoardsCreated
        numRecipesBoardsShared
      }
      enabledFeatures
      requestedFeatures
      seenMessages
      recentlyUsedRecommenderMealTypes
      recentlyUsedRecommenderCalorieSplits {
        mealType
        fraction
      }
      recentlyUsedSearchCriteria {
        searchString
        mealTypes
        cookTimeMin
        cookTimeMax
        excludeIngredients
        includeIngredients
        dietaryPreferences
        perMealNutritionConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        tags
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createObjectEditEvent = /* GraphQL */ `
  mutation CreateObjectEditEvent(
    $input: CreateObjectEditEventInput!
    $condition: ModelObjectEditEventConditionInput
  ) {
    createObjectEditEvent(input: $input, condition: $condition) {
      id
      objectID
      objectType
      section
      itemID
      oldValue
      createdAt
      groups
      updatedAt
      owner
    }
  }
`;
export const updateObjectEditEvent = /* GraphQL */ `
  mutation UpdateObjectEditEvent(
    $input: UpdateObjectEditEventInput!
    $condition: ModelObjectEditEventConditionInput
  ) {
    updateObjectEditEvent(input: $input, condition: $condition) {
      id
      objectID
      objectType
      section
      itemID
      oldValue
      createdAt
      groups
      updatedAt
      owner
    }
  }
`;
export const deleteObjectEditEvent = /* GraphQL */ `
  mutation DeleteObjectEditEvent(
    $input: DeleteObjectEditEventInput!
    $condition: ModelObjectEditEventConditionInput
  ) {
    deleteObjectEditEvent(input: $input, condition: $condition) {
      id
      objectID
      objectType
      section
      itemID
      oldValue
      createdAt
      groups
      updatedAt
      owner
    }
  }
`;
export const createMeal = /* GraphQL */ `
  mutation CreateMeal(
    $input: CreateMealInput!
    $condition: ModelMealConditionInput
  ) {
    createMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMeal = /* GraphQL */ `
  mutation UpdateMeal(
    $input: UpdateMealInput!
    $condition: ModelMealConditionInput
  ) {
    updateMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMeal = /* GraphQL */ `
  mutation DeleteMeal(
    $input: DeleteMealInput!
    $condition: ModelMealConditionInput
  ) {
    deleteMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      smorgBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      addons {
        name
      }
      additionallyReferredToBy {
        sourceType
        sourceID
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSmorgBoard = /* GraphQL */ `
  mutation CreateSmorgBoard(
    $input: CreateSmorgBoardInput!
    $condition: ModelSmorgBoardConditionInput
  ) {
    createSmorgBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        mealIDs
      }
      shareRecords {
        sharedBoardID
        version
        updatedOn
      }
      spaceMembershipID
      embeddedInContainerType
      embeddedInContainerID
      groups
      availableInMembershipTierIDs
      isMyFavouritesBoard
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSmorgBoard = /* GraphQL */ `
  mutation UpdateSmorgBoard(
    $input: UpdateSmorgBoardInput!
    $condition: ModelSmorgBoardConditionInput
  ) {
    updateSmorgBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        mealIDs
      }
      shareRecords {
        sharedBoardID
        version
        updatedOn
      }
      spaceMembershipID
      embeddedInContainerType
      embeddedInContainerID
      groups
      availableInMembershipTierIDs
      isMyFavouritesBoard
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSmorgBoard = /* GraphQL */ `
  mutation DeleteSmorgBoard(
    $input: DeleteSmorgBoardInput!
    $condition: ModelSmorgBoardConditionInput
  ) {
    deleteSmorgBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        mealIDs
      }
      shareRecords {
        sharedBoardID
        version
        updatedOn
      }
      spaceMembershipID
      embeddedInContainerType
      embeddedInContainerID
      groups
      availableInMembershipTierIDs
      isMyFavouritesBoard
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSharedMeal = /* GraphQL */ `
  mutation CreateSharedMeal(
    $input: CreateSharedMealInput!
    $condition: ModelSharedMealConditionInput
  ) {
    createSharedMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      sharedBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSharedMeal = /* GraphQL */ `
  mutation UpdateSharedMeal(
    $input: UpdateSharedMealInput!
    $condition: ModelSharedMealConditionInput
  ) {
    updateSharedMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      sharedBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSharedMeal = /* GraphQL */ `
  mutation DeleteSharedMeal(
    $input: DeleteSharedMealInput!
    $condition: ModelSharedMealConditionInput
  ) {
    deleteSharedMeal(input: $input, condition: $condition) {
      id
      schemaVersion
      sharedBoardID
      recipes {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      origin {
        originObjectType
        originObjectID
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSharedBoard = /* GraphQL */ `
  mutation CreateSharedBoard(
    $input: CreateSharedBoardInput!
    $condition: ModelSharedBoardConditionInput
  ) {
    createSharedBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      version
      accessLevel
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        sharedMealIDs
      }
      availableInMembershipTierIDs
      createdAt
      updatedAt
    }
  }
`;
export const updateSharedBoard = /* GraphQL */ `
  mutation UpdateSharedBoard(
    $input: UpdateSharedBoardInput!
    $condition: ModelSharedBoardConditionInput
  ) {
    updateSharedBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      version
      accessLevel
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        sharedMealIDs
      }
      availableInMembershipTierIDs
      createdAt
      updatedAt
    }
  }
`;
export const deleteSharedBoard = /* GraphQL */ `
  mutation DeleteSharedBoard(
    $input: DeleteSharedBoardInput!
    $condition: ModelSharedBoardConditionInput
  ) {
    deleteSharedBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      version
      accessLevel
      title
      shortDescription
      coverImageUrl
      menus {
        id
        title
        sharedMealIDs
      }
      availableInMembershipTierIDs
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      parentID
      title
      description
      spaceMembershipID
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContentEntry = /* GraphQL */ `
  mutation CreateContentEntry(
    $input: CreateContentEntryInput!
    $condition: ModelContentEntryConditionInput
  ) {
    createContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContentEntry = /* GraphQL */ `
  mutation UpdateContentEntry(
    $input: UpdateContentEntryInput!
    $condition: ModelContentEntryConditionInput
  ) {
    updateContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContentEntry = /* GraphQL */ `
  mutation DeleteContentEntry(
    $input: DeleteContentEntryInput!
    $condition: ModelContentEntryConditionInput
  ) {
    deleteContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSharedContentEntry = /* GraphQL */ `
  mutation CreateSharedContentEntry(
    $input: CreateSharedContentEntryInput!
    $condition: ModelSharedContentEntryConditionInput
  ) {
    createSharedContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSharedContentEntry = /* GraphQL */ `
  mutation UpdateSharedContentEntry(
    $input: UpdateSharedContentEntryInput!
    $condition: ModelSharedContentEntryConditionInput
  ) {
    updateSharedContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSharedContentEntry = /* GraphQL */ `
  mutation DeleteSharedContentEntry(
    $input: DeleteSharedContentEntryInput!
    $condition: ModelSharedContentEntryConditionInput
  ) {
    deleteSharedContentEntry(input: $input, condition: $condition) {
      id
      parentID
      title
      body
      coverImageUrl
      tags
      origin {
        originObjectType
        originObjectID
      }
      appLink {
        appLinkType
        appLinkScreen
        url
        objectID
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPlannerWeek = /* GraphQL */ `
  mutation CreatePlannerWeek(
    $input: CreatePlannerWeekInput!
    $condition: ModelPlannerWeekConditionInput
  ) {
    createPlannerWeek(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePlannerWeek = /* GraphQL */ `
  mutation UpdatePlannerWeek(
    $input: UpdatePlannerWeekInput!
    $condition: ModelPlannerWeekConditionInput
  ) {
    updatePlannerWeek(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePlannerWeek = /* GraphQL */ `
  mutation DeletePlannerWeek(
    $input: DeletePlannerWeekInput!
    $condition: ModelPlannerWeekConditionInput
  ) {
    deletePlannerWeek(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      startDate
      days {
        entries {
          plannerEntryType
          mealID
          objectID
        }
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createShoppingList = /* GraphQL */ `
  mutation CreateShoppingList(
    $input: CreateShoppingListInput!
    $condition: ModelShoppingListConditionInput
  ) {
    createShoppingList(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateShoppingList = /* GraphQL */ `
  mutation UpdateShoppingList(
    $input: UpdateShoppingListInput!
    $condition: ModelShoppingListConditionInput
  ) {
    updateShoppingList(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteShoppingList = /* GraphQL */ `
  mutation DeleteShoppingList(
    $input: DeleteShoppingListInput!
    $condition: ModelShoppingListConditionInput
  ) {
    deleteShoppingList(input: $input, condition: $condition) {
      id
      schemaVersion
      spaceMembershipID
      productsBoardID
      title
      shortDescription
      items {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      mealIDs
      mealImageUrls
      meals {
        mealID
        recipes {
          title
          shortDescription
          imageUrl
          recipeUrl
          publisher
          chef
          mealiqId
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          mealTypes
          tags
          supportedDiets
          ingredients {
            id
            fullText
            structuredIngredient {
              name
              quantity
              unitOfMeasure
              normalisedQuantity
              linkedIngredient {
                status
                linkedIngredientName
              }
              resolvedNutrition
              resolvedNutritionError
            }
            tokFullText
            tokens {
              fromChar
              toChar
              type
              label
            }
            scalingRules
            checkedInIngredientsList
          }
          preparations {
            id
            fullText
          }
          utensils {
            id
            fullText
          }
          servings
          cookTime
          prepTime
          totalTime
          recipeNotes
        }
      }
      activityRecords {
        activityType
        occurredOn
        vendorName
        transactionID
        shoppingListItemIDs
      }
      userItems {
        id
        name
        total {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        quantities {
          quantity
          prettyQuantity
          unitOfMeasure
        }
        linkedIngredient {
          status
          linkedIngredientName
        }
        aisleLocation
        forMealIds
        checked
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createProductsBoard = /* GraphQL */ `
  mutation CreateProductsBoard(
    $input: CreateProductsBoardInput!
    $condition: ModelProductsBoardConditionInput
  ) {
    createProductsBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      spaceMembershipID
      shoppingListGroups {
        id
        title
        shoppingListIDs
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProductsBoard = /* GraphQL */ `
  mutation UpdateProductsBoard(
    $input: UpdateProductsBoardInput!
    $condition: ModelProductsBoardConditionInput
  ) {
    updateProductsBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      spaceMembershipID
      shoppingListGroups {
        id
        title
        shoppingListIDs
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProductsBoard = /* GraphQL */ `
  mutation DeleteProductsBoard(
    $input: DeleteProductsBoardInput!
    $condition: ModelProductsBoardConditionInput
  ) {
    deleteProductsBoard(input: $input, condition: $condition) {
      id
      schemaVersion
      title
      spaceMembershipID
      shoppingListGroups {
        id
        title
        shoppingListIDs
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGlobalTag = /* GraphQL */ `
  mutation CreateGlobalTag(
    $input: CreateGlobalTagInput!
    $condition: ModelGlobalTagConditionInput
  ) {
    createGlobalTag(input: $input, condition: $condition) {
      id
      tag
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalTag = /* GraphQL */ `
  mutation UpdateGlobalTag(
    $input: UpdateGlobalTagInput!
    $condition: ModelGlobalTagConditionInput
  ) {
    updateGlobalTag(input: $input, condition: $condition) {
      id
      tag
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalTag = /* GraphQL */ `
  mutation DeleteGlobalTag(
    $input: DeleteGlobalTagInput!
    $condition: ModelGlobalTagConditionInput
  ) {
    deleteGlobalTag(input: $input, condition: $condition) {
      id
      tag
      count
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientEditEvent = /* GraphQL */ `
  mutation CreateIngredientEditEvent(
    $input: CreateIngredientEditEventInput!
    $condition: ModelIngredientEditEventConditionInput
  ) {
    createIngredientEditEvent(input: $input, condition: $condition) {
      mealID
      recipeID
      editMethod
      ingredientID
      fullTextBefore
      fullTextAfter
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIngredientEditEvent = /* GraphQL */ `
  mutation UpdateIngredientEditEvent(
    $input: UpdateIngredientEditEventInput!
    $condition: ModelIngredientEditEventConditionInput
  ) {
    updateIngredientEditEvent(input: $input, condition: $condition) {
      mealID
      recipeID
      editMethod
      ingredientID
      fullTextBefore
      fullTextAfter
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIngredientEditEvent = /* GraphQL */ `
  mutation DeleteIngredientEditEvent(
    $input: DeleteIngredientEditEventInput!
    $condition: ModelIngredientEditEventConditionInput
  ) {
    deleteIngredientEditEvent(input: $input, condition: $condition) {
      mealID
      recipeID
      editMethod
      ingredientID
      fullTextBefore
      fullTextAfter
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIngredientEditSuggestion = /* GraphQL */ `
  mutation CreateIngredientEditSuggestion(
    $input: CreateIngredientEditSuggestionInput!
    $condition: ModelIngredientEditSuggestionConditionInput
  ) {
    createIngredientEditSuggestion(input: $input, condition: $condition) {
      id
      inputFullText
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientEditSuggestion = /* GraphQL */ `
  mutation UpdateIngredientEditSuggestion(
    $input: UpdateIngredientEditSuggestionInput!
    $condition: ModelIngredientEditSuggestionConditionInput
  ) {
    updateIngredientEditSuggestion(input: $input, condition: $condition) {
      id
      inputFullText
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientEditSuggestion = /* GraphQL */ `
  mutation DeleteIngredientEditSuggestion(
    $input: DeleteIngredientEditSuggestionInput!
    $condition: ModelIngredientEditSuggestionConditionInput
  ) {
    deleteIngredientEditSuggestion(input: $input, condition: $condition) {
      id
      inputFullText
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const createIngredientEditSuggestionByIngredientID = /* GraphQL */ `
  mutation CreateIngredientEditSuggestionByIngredientID(
    $input: CreateIngredientEditSuggestionByIngredientIDInput!
    $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
  ) {
    createIngredientEditSuggestionByIngredientID(
      input: $input
      condition: $condition
    ) {
      id
      ingredientID
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const updateIngredientEditSuggestionByIngredientID = /* GraphQL */ `
  mutation UpdateIngredientEditSuggestionByIngredientID(
    $input: UpdateIngredientEditSuggestionByIngredientIDInput!
    $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
  ) {
    updateIngredientEditSuggestionByIngredientID(
      input: $input
      condition: $condition
    ) {
      id
      ingredientID
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const deleteIngredientEditSuggestionByIngredientID = /* GraphQL */ `
  mutation DeleteIngredientEditSuggestionByIngredientID(
    $input: DeleteIngredientEditSuggestionByIngredientIDInput!
    $condition: ModelIngredientEditSuggestionByIngredientIDConditionInput
  ) {
    deleteIngredientEditSuggestionByIngredientID(
      input: $input
      condition: $condition
    ) {
      id
      ingredientID
      suggestion
      weight
      createdAt
      updatedAt
    }
  }
`;
export const createProgramme = /* GraphQL */ `
  mutation CreateProgramme(
    $input: CreateProgrammeInput!
    $condition: ModelProgrammeConditionInput
  ) {
    createProgramme(input: $input, condition: $condition) {
      id
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      recipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      shareRecords {
        sharedProgrammeID
        version
        updatedOn
      }
      availableInMembershipTierIDs
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateProgramme = /* GraphQL */ `
  mutation UpdateProgramme(
    $input: UpdateProgrammeInput!
    $condition: ModelProgrammeConditionInput
  ) {
    updateProgramme(input: $input, condition: $condition) {
      id
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      recipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      shareRecords {
        sharedProgrammeID
        version
        updatedOn
      }
      availableInMembershipTierIDs
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteProgramme = /* GraphQL */ `
  mutation DeleteProgramme(
    $input: DeleteProgrammeInput!
    $condition: ModelProgrammeConditionInput
  ) {
    deleteProgramme(input: $input, condition: $condition) {
      id
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      recipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      shareRecords {
        sharedProgrammeID
        version
        updatedOn
      }
      availableInMembershipTierIDs
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSharedProgramme = /* GraphQL */ `
  mutation CreateSharedProgramme(
    $input: CreateSharedProgrammeInput!
    $condition: ModelSharedProgrammeConditionInput
  ) {
    createSharedProgramme(input: $input, condition: $condition) {
      spaceID
      programmeID
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      sharedRecipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      version
      availableInMembershipTierIDs
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSharedProgramme = /* GraphQL */ `
  mutation UpdateSharedProgramme(
    $input: UpdateSharedProgrammeInput!
    $condition: ModelSharedProgrammeConditionInput
  ) {
    updateSharedProgramme(input: $input, condition: $condition) {
      spaceID
      programmeID
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      sharedRecipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      version
      availableInMembershipTierIDs
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSharedProgramme = /* GraphQL */ `
  mutation DeleteSharedProgramme(
    $input: DeleteSharedProgrammeInput!
    $condition: ModelSharedProgrammeConditionInput
  ) {
    deleteSharedProgramme(input: $input, condition: $condition) {
      spaceID
      programmeID
      title
      shortDescription
      description
      coverImageUrl
      plans {
        id
        title
        shortDescription
        description
        coverImageUrl
        days {
          id
          title
          entries {
            id
            programmeEntryType
            objectID
          }
        }
        nutritionConstraints {
          nutritionMetricConstraints {
            id
            nutritionMetric
            operator
            value
            units
          }
          excludeIngredients
          foodPreferences
        }
        showNutritionToUsers
      }
      locales
      mealTypes
      calorieSplits {
        mealType
        fraction
      }
      nutritionConstraints {
        nutritionMetricConstraints {
          id
          nutritionMetric
          operator
          value
          units
        }
        excludeIngredients
        foodPreferences
      }
      showNutritionToUsers
      personalisedMealScaling
      recipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      databaseRecipesBoard {
        id
        title
        menus {
          id
          title
          mealIDs
        }
        embeddedInContainerType
        embeddedInContainerID
      }
      sharedRecipesBoardIDs
      onboardingConfiguration {
        questionIDs
      }
      categoryTags
      version
      availableInMembershipTierIDs
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserProgrammeEnrollment = /* GraphQL */ `
  mutation CreateUserProgrammeEnrollment(
    $input: CreateUserProgrammeEnrollmentInput!
    $condition: ModelUserProgrammeEnrollmentConditionInput
  ) {
    createUserProgrammeEnrollment(input: $input, condition: $condition) {
      spaceMembershipID
      sharedProgrammeID
      enrolledAt
      onboardingAnswers {
        questionID
        answer
      }
      endedAt
      eventRecords {
        calendarDay
        eventType
        updatedOn
      }
      customTargetCalories
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserProgrammeEnrollment = /* GraphQL */ `
  mutation UpdateUserProgrammeEnrollment(
    $input: UpdateUserProgrammeEnrollmentInput!
    $condition: ModelUserProgrammeEnrollmentConditionInput
  ) {
    updateUserProgrammeEnrollment(input: $input, condition: $condition) {
      spaceMembershipID
      sharedProgrammeID
      enrolledAt
      onboardingAnswers {
        questionID
        answer
      }
      endedAt
      eventRecords {
        calendarDay
        eventType
        updatedOn
      }
      customTargetCalories
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserProgrammeEnrollment = /* GraphQL */ `
  mutation DeleteUserProgrammeEnrollment(
    $input: DeleteUserProgrammeEnrollmentInput!
    $condition: ModelUserProgrammeEnrollmentConditionInput
  ) {
    deleteUserProgrammeEnrollment(input: $input, condition: $condition) {
      spaceMembershipID
      sharedProgrammeID
      enrolledAt
      onboardingAnswers {
        questionID
        answer
      }
      endedAt
      eventRecords {
        calendarDay
        eventType
        updatedOn
      }
      customTargetCalories
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGRCRecipe = /* GraphQL */ `
  mutation CreateGRCRecipe(
    $input: CreateGRCRecipeInput!
    $condition: ModelGRCRecipeConditionInput
  ) {
    createGRCRecipe(input: $input, condition: $condition) {
      grcRecipeID
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGRCRecipe = /* GraphQL */ `
  mutation UpdateGRCRecipe(
    $input: UpdateGRCRecipeInput!
    $condition: ModelGRCRecipeConditionInput
  ) {
    updateGRCRecipe(input: $input, condition: $condition) {
      grcRecipeID
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGRCRecipe = /* GraphQL */ `
  mutation DeleteGRCRecipe(
    $input: DeleteGRCRecipeInput!
    $condition: ModelGRCRecipeConditionInput
  ) {
    deleteGRCRecipe(input: $input, condition: $condition) {
      grcRecipeID
      recipe {
        title
        shortDescription
        imageUrl
        recipeUrl
        publisher
        chef
        mealiqId
        nutrition {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        mealTypes
        tags
        supportedDiets
        ingredients {
          id
          fullText
          structuredIngredient {
            name
            quantity
            unitOfMeasure
            normalisedQuantity
            linkedIngredient {
              status
              linkedIngredientName
            }
            resolvedNutrition
            resolvedNutritionError
          }
          tokFullText
          tokens {
            fromChar
            toChar
            type
            label
          }
          scalingRules
          checkedInIngredientsList
        }
        preparations {
          id
          fullText
        }
        utensils {
          id
          fullText
        }
        servings
        cookTime
        prepTime
        totalTime
        recipeNotes
      }
      derivedNutrition {
        totalNutritionPerServing {
          calories
          protein
          carbohydrate
          fat
          fibre
          sugar
          saturates
          salt
          veg_weight
          cholesterol
          calcium
          iron
          magnesium
          potassium
          vitamin_a
          vitamin_b1
          vitamin_b2
          vitamin_b3
          vitamin_b5
          vitamin_b6
          vitamin_b12
          vitamin_c
          vitamin_d
          vitamin_e
          vitamin_k
          phosphorous
          zinc
          copper
          manganese
          selenium
          folate
          omega3_dha
          omega3_dpa
          omega3_epa
          sucrose
          glucose
          fructose
          lactose
          maltose
        }
        ingredientNutrition {
          ingredientID
          nutrition {
            calories
            protein
            carbohydrate
            fat
            fibre
            sugar
            saturates
            salt
            veg_weight
            cholesterol
            calcium
            iron
            magnesium
            potassium
            vitamin_a
            vitamin_b1
            vitamin_b2
            vitamin_b3
            vitamin_b5
            vitamin_b6
            vitamin_b12
            vitamin_c
            vitamin_d
            vitamin_e
            vitamin_k
            phosphorous
            zinc
            copper
            manganese
            selenium
            folate
            omega3_dha
            omega3_dpa
            omega3_epa
            sucrose
            glucose
            fructose
            lactose
            maltose
          }
          resolvedNutrition
          error
          resolvedIngredientWeight
        }
        foodAllergens
        foodPreferences
        status
        macroRatio {
          carbohydrate
          protein
          fat
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpaceIAPSubscription = /* GraphQL */ `
  mutation CreateSpaceIAPSubscription(
    $input: CreateSpaceIAPSubscriptionInput!
    $condition: ModelSpaceIAPSubscriptionConditionInput
  ) {
    createSpaceIAPSubscription(input: $input, condition: $condition) {
      spaceID
      productID
      title
      shortDescription
      currencyCode
      priceStr
      bundleID
      duration
      signUpButtonLabelOverride
      appStoreID
      rank
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSpaceIAPSubscription = /* GraphQL */ `
  mutation UpdateSpaceIAPSubscription(
    $input: UpdateSpaceIAPSubscriptionInput!
    $condition: ModelSpaceIAPSubscriptionConditionInput
  ) {
    updateSpaceIAPSubscription(input: $input, condition: $condition) {
      spaceID
      productID
      title
      shortDescription
      currencyCode
      priceStr
      bundleID
      duration
      signUpButtonLabelOverride
      appStoreID
      rank
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSpaceIAPSubscription = /* GraphQL */ `
  mutation DeleteSpaceIAPSubscription(
    $input: DeleteSpaceIAPSubscriptionInput!
    $condition: ModelSpaceIAPSubscriptionConditionInput
  ) {
    deleteSpaceIAPSubscription(input: $input, condition: $condition) {
      spaceID
      productID
      title
      shortDescription
      currencyCode
      priceStr
      bundleID
      duration
      signUpButtonLabelOverride
      appStoreID
      rank
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      title
      shortDescription
      whiteLabelElements {
        primaryMainColor
        backgroundStyle
        backgroundColor
        backgroundGradientColor
        cardBackgroundColor
        iconUrl
        splashImageUrl
        myDayBackgroundImageUrl
        desktopBannerImageUrl
        termsUrl
        privacyPolicyUrl
      }
      adminUserIDs
      landingPageContent
      landingPageStyle {
        backgroundColor
      }
      landingPageCoverImageUrl
      isDefaultSpace
      sharedProgrammeConfiguration {
        sharedProgrammeCategories {
          categoryTag
          orderedProgrammeIDs
        }
      }
      productPreviewSections {
        title
        productPreviews {
          productType
          productObjectID
          title
          shortDescription
          coverImageUrl
          availableInMembershipTierIDs
        }
      }
      videoPlaylists {
        title
        playlistID
        items {
          videoID
          title
          description
          thumbnailImageUrl
        }
      }
      spaceContentBoard {
        id
        title
        lanes {
          id
          title
          entries {
            id
            entryType
            objectID
          }
        }
      }
      welcomeVideo {
        videoID
        title
        description
        thumbnailImageUrl
      }
      myDayScreenSections {
        publishedSectionType
        spaceContentLaneID
      }
      subscriptionBenefitsContent
      subscriptionPageStyle {
        backgroundColor
      }
      groups
      sharedRecipesBoardIDs
      defaultSharedProgrammeIDForNewUsers
      appStoreLinks {
        appStoreID
        appUrl
      }
      chatConfig {
        appID
        appRegion
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      title
      shortDescription
      whiteLabelElements {
        primaryMainColor
        backgroundStyle
        backgroundColor
        backgroundGradientColor
        cardBackgroundColor
        iconUrl
        splashImageUrl
        myDayBackgroundImageUrl
        desktopBannerImageUrl
        termsUrl
        privacyPolicyUrl
      }
      adminUserIDs
      landingPageContent
      landingPageStyle {
        backgroundColor
      }
      landingPageCoverImageUrl
      isDefaultSpace
      sharedProgrammeConfiguration {
        sharedProgrammeCategories {
          categoryTag
          orderedProgrammeIDs
        }
      }
      productPreviewSections {
        title
        productPreviews {
          productType
          productObjectID
          title
          shortDescription
          coverImageUrl
          availableInMembershipTierIDs
        }
      }
      videoPlaylists {
        title
        playlistID
        items {
          videoID
          title
          description
          thumbnailImageUrl
        }
      }
      spaceContentBoard {
        id
        title
        lanes {
          id
          title
          entries {
            id
            entryType
            objectID
          }
        }
      }
      welcomeVideo {
        videoID
        title
        description
        thumbnailImageUrl
      }
      myDayScreenSections {
        publishedSectionType
        spaceContentLaneID
      }
      subscriptionBenefitsContent
      subscriptionPageStyle {
        backgroundColor
      }
      groups
      sharedRecipesBoardIDs
      defaultSharedProgrammeIDForNewUsers
      appStoreLinks {
        appStoreID
        appUrl
      }
      chatConfig {
        appID
        appRegion
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      title
      shortDescription
      whiteLabelElements {
        primaryMainColor
        backgroundStyle
        backgroundColor
        backgroundGradientColor
        cardBackgroundColor
        iconUrl
        splashImageUrl
        myDayBackgroundImageUrl
        desktopBannerImageUrl
        termsUrl
        privacyPolicyUrl
      }
      adminUserIDs
      landingPageContent
      landingPageStyle {
        backgroundColor
      }
      landingPageCoverImageUrl
      isDefaultSpace
      sharedProgrammeConfiguration {
        sharedProgrammeCategories {
          categoryTag
          orderedProgrammeIDs
        }
      }
      productPreviewSections {
        title
        productPreviews {
          productType
          productObjectID
          title
          shortDescription
          coverImageUrl
          availableInMembershipTierIDs
        }
      }
      videoPlaylists {
        title
        playlistID
        items {
          videoID
          title
          description
          thumbnailImageUrl
        }
      }
      spaceContentBoard {
        id
        title
        lanes {
          id
          title
          entries {
            id
            entryType
            objectID
          }
        }
      }
      welcomeVideo {
        videoID
        title
        description
        thumbnailImageUrl
      }
      myDayScreenSections {
        publishedSectionType
        spaceContentLaneID
      }
      subscriptionBenefitsContent
      subscriptionPageStyle {
        backgroundColor
      }
      groups
      sharedRecipesBoardIDs
      defaultSharedProgrammeIDForNewUsers
      appStoreLinks {
        appStoreID
        appUrl
      }
      chatConfig {
        appID
        appRegion
      }
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSpaceMembership = /* GraphQL */ `
  mutation CreateSpaceMembership(
    $input: CreateSpaceMembershipInput!
    $condition: ModelSpaceMembershipConditionInput
  ) {
    createSpaceMembership(input: $input, condition: $condition) {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateSpaceMembership = /* GraphQL */ `
  mutation UpdateSpaceMembership(
    $input: UpdateSpaceMembershipInput!
    $condition: ModelSpaceMembershipConditionInput
  ) {
    updateSpaceMembership(input: $input, condition: $condition) {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteSpaceMembership = /* GraphQL */ `
  mutation DeleteSpaceMembership(
    $input: DeleteSpaceMembershipInput!
    $condition: ModelSpaceMembershipConditionInput
  ) {
    deleteSpaceMembership(input: $input, condition: $condition) {
      spaceID
      memberID
      state
      correlatedUserID
      expiresAt
      visibleProgrammeIDsOverride
      membershipTierID
      stripeCustomerID
      stripeSubscriptionID
      onboardingAnswers {
        questionID
        answer
      }
      onboardingAnswersLastUpdatedAt
      customTargetCalories
      welcomeVideoWatched
      initialProgrammeSelectionLastOfferedAt
      chatNickname
      referrer
      appStoreSubscriptionID
      groups
      createdAt
      id
      updatedAt
    }
  }
`;
export const createSpaceMembershipEvent = /* GraphQL */ `
  mutation CreateSpaceMembershipEvent(
    $input: CreateSpaceMembershipEventInput!
    $condition: ModelSpaceMembershipEventConditionInput
  ) {
    createSpaceMembershipEvent(input: $input, condition: $condition) {
      spaceMembershipID
      memberID
      eventType
      transactionSource
      transactionID
      details
      createdAt
      id
      updatedAt
    }
  }
`;
export const updateSpaceMembershipEvent = /* GraphQL */ `
  mutation UpdateSpaceMembershipEvent(
    $input: UpdateSpaceMembershipEventInput!
    $condition: ModelSpaceMembershipEventConditionInput
  ) {
    updateSpaceMembershipEvent(input: $input, condition: $condition) {
      spaceMembershipID
      memberID
      eventType
      transactionSource
      transactionID
      details
      createdAt
      id
      updatedAt
    }
  }
`;
export const deleteSpaceMembershipEvent = /* GraphQL */ `
  mutation DeleteSpaceMembershipEvent(
    $input: DeleteSpaceMembershipEventInput!
    $condition: ModelSpaceMembershipEventConditionInput
  ) {
    deleteSpaceMembershipEvent(input: $input, condition: $condition) {
      spaceMembershipID
      memberID
      eventType
      transactionSource
      transactionID
      details
      createdAt
      id
      updatedAt
    }
  }
`;
export const createUserMyDayActionRecord = /* GraphQL */ `
  mutation CreateUserMyDayActionRecord(
    $input: CreateUserMyDayActionRecordInput!
    $condition: ModelUserMyDayActionRecordConditionInput
  ) {
    createUserMyDayActionRecord(input: $input, condition: $condition) {
      userProgrammeEnrollmentID
      programmeEntryType
      objectID
      calendarDay
      actionType
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserMyDayActionRecord = /* GraphQL */ `
  mutation UpdateUserMyDayActionRecord(
    $input: UpdateUserMyDayActionRecordInput!
    $condition: ModelUserMyDayActionRecordConditionInput
  ) {
    updateUserMyDayActionRecord(input: $input, condition: $condition) {
      userProgrammeEnrollmentID
      programmeEntryType
      objectID
      calendarDay
      actionType
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserMyDayActionRecord = /* GraphQL */ `
  mutation DeleteUserMyDayActionRecord(
    $input: DeleteUserMyDayActionRecordInput!
    $condition: ModelUserMyDayActionRecordConditionInput
  ) {
    deleteUserMyDayActionRecord(input: $input, condition: $condition) {
      userProgrammeEnrollmentID
      programmeEntryType
      objectID
      calendarDay
      actionType
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createMembershipTier = /* GraphQL */ `
  mutation CreateMembershipTier(
    $input: CreateMembershipTierInput!
    $condition: ModelMembershipTierConditionInput
  ) {
    createMembershipTier(input: $input, condition: $condition) {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateMembershipTier = /* GraphQL */ `
  mutation UpdateMembershipTier(
    $input: UpdateMembershipTierInput!
    $condition: ModelMembershipTierConditionInput
  ) {
    updateMembershipTier(input: $input, condition: $condition) {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteMembershipTier = /* GraphQL */ `
  mutation DeleteMembershipTier(
    $input: DeleteMembershipTierInput!
    $condition: ModelMembershipTierConditionInput
  ) {
    deleteMembershipTier(input: $input, condition: $condition) {
      spaceID
      title
      shortDescription
      monthlyPrice
      quarterlyPrice
      annualPrice
      freeTrialDurationDays
      rank
      currencyCode
      state
      stripeProductID
      stripePriceID
      stripePriceIDQuarterly
      stripePriceIDAnnual
      groups
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSpacePrivateConfig = /* GraphQL */ `
  mutation CreateSpacePrivateConfig(
    $input: CreateSpacePrivateConfigInput!
    $condition: ModelSpacePrivateConfigConditionInput
  ) {
    createSpacePrivateConfig(input: $input, condition: $condition) {
      id
      spaceID
      stripeConfig {
        environment
        connectedAccountID
        chargesEnabled
        stripeProductID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSpacePrivateConfig = /* GraphQL */ `
  mutation UpdateSpacePrivateConfig(
    $input: UpdateSpacePrivateConfigInput!
    $condition: ModelSpacePrivateConfigConditionInput
  ) {
    updateSpacePrivateConfig(input: $input, condition: $condition) {
      id
      spaceID
      stripeConfig {
        environment
        connectedAccountID
        chargesEnabled
        stripeProductID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSpacePrivateConfig = /* GraphQL */ `
  mutation DeleteSpacePrivateConfig(
    $input: DeleteSpacePrivateConfigInput!
    $condition: ModelSpacePrivateConfigConditionInput
  ) {
    deleteSpacePrivateConfig(input: $input, condition: $condition) {
      id
      spaceID
      stripeConfig {
        environment
        connectedAccountID
        chargesEnabled
        stripeProductID
      }
      groups
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAccountActivitySignal = /* GraphQL */ `
  mutation CreateAccountActivitySignal(
    $input: CreateAccountActivitySignalInput!
    $condition: ModelAccountActivitySignalConditionInput
  ) {
    createAccountActivitySignal(input: $input, condition: $condition) {
      id
      spaceID
      groups
      lastActivityAt
      friendlyUsername
      lastActivityObjectType
      lastActivityObjectID
      sourceIp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccountActivitySignal = /* GraphQL */ `
  mutation UpdateAccountActivitySignal(
    $input: UpdateAccountActivitySignalInput!
    $condition: ModelAccountActivitySignalConditionInput
  ) {
    updateAccountActivitySignal(input: $input, condition: $condition) {
      id
      spaceID
      groups
      lastActivityAt
      friendlyUsername
      lastActivityObjectType
      lastActivityObjectID
      sourceIp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccountActivitySignal = /* GraphQL */ `
  mutation DeleteAccountActivitySignal(
    $input: DeleteAccountActivitySignalInput!
    $condition: ModelAccountActivitySignalConditionInput
  ) {
    deleteAccountActivitySignal(input: $input, condition: $condition) {
      id
      spaceID
      groups
      lastActivityAt
      friendlyUsername
      lastActivityObjectType
      lastActivityObjectID
      sourceIp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $email: AWSEmail!
    $givenName: String
    $familyName: String
  ) {
    createContact(email: $email, givenName: $givenName, familyName: $familyName)
  }
`;
export const emailWelcomeMessage = /* GraphQL */ `
  mutation EmailWelcomeMessage(
    $recipient: AWSEmail!
    $givenName: String
    $familyName: String
  ) {
    emailWelcomeMessage(
      recipient: $recipient
      givenName: $givenName
      familyName: $familyName
    )
  }
`;
export const emailHealthProWelcomeMessage = /* GraphQL */ `
  mutation EmailHealthProWelcomeMessage(
    $recipient: AWSEmail!
    $givenName: String
    $familyName: String
  ) {
    emailHealthProWelcomeMessage(
      recipient: $recipient
      givenName: $givenName
      familyName: $familyName
    )
  }
`;
export const emailWelcomeToSpaceMessage = /* GraphQL */ `
  mutation EmailWelcomeToSpaceMessage(
    $recipient: AWSEmail!
    $givenName: String
    $familyName: String
    $spaceTitle: String!
    $spaceSubscribeUrl: String!
  ) {
    emailWelcomeToSpaceMessage(
      recipient: $recipient
      givenName: $givenName
      familyName: $familyName
      spaceTitle: $spaceTitle
      spaceSubscribeUrl: $spaceSubscribeUrl
    )
  }
`;
export const getIngredientTreeNodes = /* GraphQL */ `
  mutation GetIngredientTreeNodes($locale: String) {
    getIngredientTreeNodes(locale: $locale) {
      ingredientName
      parentIngredientName
    }
  }
`;
