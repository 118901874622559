import React, { useRef, useState } from 'react';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { Button, Grid, IconButton, Popover } from '@mui/material';
import { TwitterPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { updateSpaceAction } from '../../action_creators/spaces_action_creators';
import HtmlEditor from '../meal/html_editor';
import ContentMobilePreview from '../programmes/content_mobile_preview';
import {
  COLOR_PICKER_BACKGROUND_COLORS,
  DEFAULT_SMORG_BACKGROUND_COLOR,
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
  subscriptionPagePreviewHtml,
} from '../../services/spaces';
import { deepEqual } from '../../services/smorg_redux_store';
import ColorIndicator from '../common/color_indicator';

const SpaceSubscriptionPageEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);

  const navigate = useNavigate();

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [
    subscriptionBenefitsPreviewContent,
    setSubscriptionBenefitsPreviewContent,
  ] = useState(space?.subscriptionBenefitsContent);

  const [subscriptionPageStyle, setSubscriptionPageStyle] = useState(
    space?.subscriptionPageStyle,
  );

  const joinButtonColor =
    space?.whiteLabelElements?.primaryMainColor ||
    DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const backgroundColor =
    subscriptionPageStyle?.backgroundColor ||
    space?.whiteLabelElements?.backgroundColor ||
    DEFAULT_SMORG_BACKGROUND_COLOR;

  const cardBackgroundColor =
    space?.whiteLabelElements?.cardBackgroundColor ||
    space?.whiteLabelElements?.primaryMainColor;

  const membershipTiers = useSelector(
    (state) => state.membershipTiers,
    deepEqual,
  );

  const previewHtml = subscriptionPagePreviewHtml(
    subscriptionBenefitsPreviewContent,
    joinButtonColor,
    backgroundColor,
    cardBackgroundColor,
    membershipTiers && membershipTiers[0],
  );

  const dispatch = useDispatch();

  const [backgroundColorPickerAnchorEl, setBackgroundColorPickerAnchorEl] =
    useState(null);

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        subscriptionBenefitsContent: editorRef.current.getContent(),
        subscriptionPageStyle,
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const editorRef = useRef(null);

  const onBackgroundColorChosen = (color) => {
    setSubscriptionPageStyle((oldSubscriptionPageStyle) => ({
      ...oldSubscriptionPageStyle,
      backgroundColor: color.hex,
    }));
    setBackgroundColorPickerAnchorEl(null);
    afterChange();
  };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  return (
    <div className="userProfile">
      <div className="banner">
        <IconButton
          aria-label="close"
          onClick={onGoBack}
          size="small"
          style={{ float: 'right', marginTop: -7 }}>
          <CloseIcon />
        </IconButton>
        Edit your subscription page
      </div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Set page color</h5>
            </Grid>
            <Grid item xs={9}>
              <ColorIndicator
                color={backgroundColor}
                onClick={(ev) => {
                  setBackgroundColorPickerAnchorEl(ev.target);
                }}
              />
              {!!backgroundColorPickerAnchorEl && (
                <Popover
                  open
                  anchorEl={backgroundColorPickerAnchorEl}
                  onClose={() => setBackgroundColorPickerAnchorEl(false)}>
                  <TwitterPicker
                    colors={COLOR_PICKER_BACKGROUND_COLORS}
                    color={backgroundColor}
                    onChangeComplete={onBackgroundColorChosen}
                    title="Pick a color"
                  />
                </Popover>
              )}
            </Grid>
            <Grid item xs={3}>
              &nbsp;
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Create subscription page content</h5>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 10, paddingBottom: 10 }}>
              Create the subscription page for your app. Tell your customers why
              they should pay for your service.
            </Grid>
            <Grid item xs={12}>
              <HtmlEditor
                initialHtml={space?.subscriptionBenefitsContent}
                height={530}
                width="100%"
                editorRef={editorRef}
                onChange={() => {
                  afterChange();
                }}
              />
              <div
                style={{
                  fontWeight: 'bold',
                  marginTop: 5,
                }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  style={{ textDecoration: 'none' }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionBenefitsPreviewContent(
                      editorRef.current.getContent(),
                    );
                  }}>
                  Preview in emulator
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} style={{ paddingTop: 40 }}>
          <ContentMobilePreview html={previewHtml} zoomFactor={0.75} />
        </Grid>
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDirty || isLoading}>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpaceSubscriptionPageEditForm;
