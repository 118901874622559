import { Action, ActionOnClickType, ContentToDisplay } from ".";
import {
  CometChat,
  Group,
  GroupMembersRequestBuilder,
  User,
  UsersRequestBuilder,
} from "@cometchat/chat-sdk-javascript";
import {
  CometChatMentionsFormatter,
  CometChatTextFormatter,
  UserMemberWrapperConfiguration,
  UserMentionStyle,
} from "@cometchat/uikit-shared";
import {
  CometChatMessageEvents,
  CometChatUIEvents,
  IMentionsCountWarning,
  IMessages,
  IModal,
  MessageStatus,
  UserMemberListType,
} from "@cometchat/uikit-resources";
import React, { JSX, useEffect, useRef } from "react";

type Args = {
  dispatch: React.Dispatch<Action>;
  textInputRef: React.MutableRefObject<
    JSX.IntrinsicElements["cometchat-message-input"] | null
  >;
  liveReactionBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  LiveReactionIconURL: string;
  mySetAddToMsgInputText: (text: string) => void;
  actionSheetElement: JSX.IntrinsicElements["cometchat-action-sheet"] | null;
  mediaFilePickerRef: React.MutableRefObject<HTMLInputElement | null>;
  secondaryBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  textMessageEditPreviewElement:
  | JSX.IntrinsicElements["cometchat-preview"]
  | null;
  auxiliaryBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  voiceRecordingBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  emojiKeyboardElement:
  | JSX.IntrinsicElements["cometchat-emoji-keyboard"]
  | null;
  voiceRecordingElement:
  | JSX.IntrinsicElements["cometchat-emoji-keyboard"]
  | null;
  auxiliaryPopoverElement: JSX.IntrinsicElements["cometchat-popover"] | null;
  attachmentPopoverElement: JSX.IntrinsicElements["cometchat-popover"] | null;
  text: string;
  handleSendButtonClick: (text: string) => Promise<void>;
  primaryBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  onTextChange?: (text: string) => void;
  actionIdToActionOnClick: React.MutableRefObject<
    Map<string, ActionOnClickType>
  >;
  handleTyping: () => void;
  errorHandler: (error: unknown) => void;
  getReceiverDetails: () => { receiverId: string; receiverType: string };
  contentToDisplay: ContentToDisplay;
  createPollViewRef: any;
  handleSendVoiceMessage: (blob: Blob) => Promise<void>;
  aiPopoverElement: JSX.IntrinsicElements["cometchat-popover"] | null;
  aiBtnElement: JSX.IntrinsicElements["cometchat-button"] | null;
  setSmartRepliesView: any;
  textFormatters: Array<CometChatTextFormatter>;
  disableMentions: boolean;
  textFormatterArray: Array<CometChatTextFormatter>;
  mentionsTextFormatterInstanceRef: React.MutableRefObject<CometChatMentionsFormatter>;
  userMemberWrapperConfiguration: UserMemberWrapperConfiguration | undefined;
  setTextFormatters: React.Dispatch<
    React.SetStateAction<CometChatTextFormatter[]>
  >;
  CometChatUIKitLoginListener: any;
  group: CometChat.Group | undefined;
  user: CometChat.User | undefined;
  userMemberWrapperConfig: UserMemberWrapperConfiguration;
  setUserMemberWrapperConfig: React.Dispatch<
    React.SetStateAction<UserMemberWrapperConfiguration>
  >;
  userPropRef: React.MutableRefObject<User | undefined>;
  groupPropRef: React.MutableRefObject<Group | undefined>;
  setShowListForMentions: any;
  searchMentions: Function;
  mentionsFormatterInstanceId: string;
  setUsersRequestBuilder: React.Dispatch<
    React.SetStateAction<UsersRequestBuilder | undefined>
  >;
  setGroupMembersRequestBuilder: React.Dispatch<
    React.SetStateAction<GroupMembersRequestBuilder | undefined>
  >;
  setUserMemberListType: React.Dispatch<
    React.SetStateAction<UserMemberListType | undefined>
  >;
  userMemberWrapperRef: any;
  getComposerId: Function;
  parentMessageIdPropRef: any;
  propsText:string | undefined
};

export function Hooks(args: Args) {
  const {
    dispatch,
    textInputRef,
    liveReactionBtnElement,
    LiveReactionIconURL,
    mySetAddToMsgInputText,
    actionSheetElement,
    mediaFilePickerRef,
    secondaryBtnElement,
    textMessageEditPreviewElement,
    auxiliaryBtnElement,
    voiceRecordingBtnElement,
    emojiKeyboardElement,
    voiceRecordingElement,
    auxiliaryPopoverElement,
    attachmentPopoverElement,
    text,
    handleSendButtonClick,
    primaryBtnElement,
    onTextChange,
    actionIdToActionOnClick,
    handleTyping,
    errorHandler,
    getReceiverDetails,
    contentToDisplay,
    createPollViewRef,
    handleSendVoiceMessage,
    aiPopoverElement,
    aiBtnElement,
    setSmartRepliesView,
    textFormatters,
    disableMentions,
    textFormatterArray,
    mentionsTextFormatterInstanceRef,
    userMemberWrapperConfiguration,
    setTextFormatters,
    CometChatUIKitLoginListener,
    group,
    user,
    userMemberWrapperConfig,
    setUserMemberWrapperConfig,
    userPropRef,
    groupPropRef,
    setShowListForMentions,
    searchMentions,
    mentionsFormatterInstanceId,
    setUsersRequestBuilder,
    setGroupMembersRequestBuilder,
    setUserMemberListType,
    userMemberWrapperRef,
    getComposerId,
    parentMessageIdPropRef,
    propsText } = args;
  const isPreviewVisible = useRef<boolean>(false);




 useEffect(() => {
   // Maintain the initial text passed from props when the conversation changes
   if (propsText && (user?.getUid() || group?.getGuid())) {
     dispatch({ type: "setAddToMsgInputText", addToMsgInputText: propsText });
   }
 }, [user?.getUid(), group?.getGuid(), propsText, dispatch]);


  useEffect(
    /**
     * Subscribes to message edited Message UI event
     */
    () => {
      const subMessageEdited = CometChatMessageEvents.ccMessageEdited.subscribe(
        (object: IMessages) => {
          let parentId = object?.message?.getParentMessageId()
          if ((parentMessageIdPropRef.current && parentId
            && parentId === parentMessageIdPropRef.current)
            || (!parentMessageIdPropRef.current && !parentId)) {
            if (
              object.status === MessageStatus.inprogress &&
              object.message instanceof CometChat.TextMessage
            ) {
              isPreviewVisible.current = true;
              dispatch({
                type: "setTextMessageToEdit",
                textMessageToEdit: object.message,
              });
              textInputRef.current?.emptyInputField();
              mySetAddToMsgInputText(object.message.getText());
            }
            else {
              isPreviewVisible.current = true;
            }
          }
        }
      );
      const subComposeMessage = CometChatUIEvents.ccComposeMessage.subscribe(
        (text: string) => {
          dispatch({ type: "setText", text: "" });
          textInputRef.current?.emptyInputField();
          mySetAddToMsgInputText(text);
        }
      );
      mentionsTextFormatterInstanceRef.current.setId(
        mentionsFormatterInstanceId
      );

      const ccShowMentionsCountWarning =
        CometChatUIEvents.ccShowMentionsCountWarning.subscribe(
          (data: IMentionsCountWarning) => {
            if (data.id === mentionsFormatterInstanceId) {
              if (data.showWarning) {
                dispatch({
                  type: "setShowMentionsCountWarning",
                  showMentionsCountWarning: true,
                });
                return;
              }
              dispatch({
                type: "setShowMentionsCountWarning",
                showMentionsCountWarning: false,
              });
            }
          }
        );
      return () => {
        subMessageEdited.unsubscribe();
        subComposeMessage.unsubscribe();
        ccShowMentionsCountWarning.unsubscribe();
      };
    },
    [
      mySetAddToMsgInputText,
      dispatch,
      textInputRef,
      mentionsFormatterInstanceId,
    ]
  );

  useEffect(
    /**
     * Add `cc-button-clicked` event listener to the live reaction button element
     */
    () => {
      if (!liveReactionBtnElement) {
        return;
      }
      async function handleEvent() {
        try {
          const reactionURL = LiveReactionIconURL;
          const { receiverId, receiverType } = getReceiverDetails();
          const data = {
            type: "live_reaction",
            reaction: "heart",
          };
          CometChat.sendTransientMessage(
            new CometChat.TransientMessage(receiverId, receiverType, data)
          );
          CometChatMessageEvents.ccLiveReaction.next("heart");
        } catch (error) {
          errorHandler(error);
        }
      }
      const eventName = "cc-button-clicked";
      liveReactionBtnElement.addEventListener(eventName, handleEvent);
      return () => {
        liveReactionBtnElement.removeEventListener(eventName, handleEvent);
      };
    },
    [
      LiveReactionIconURL,
      liveReactionBtnElement,
      errorHandler,
      getReceiverDetails,
    ]
  );

  useEffect(
    /**
     * Add `cc-actionsheet-clicked` event listener to the action sheet element
     */
    () => {
      if (!actionSheetElement || !mediaFilePickerRef.current) {
        return;
      }
      function handleEvent(e: CustomEvent) {
        const { action } = e.detail;
        // Hide the secondary content view
        secondaryBtnElement?.click();
        dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
        const actionOnClick = actionIdToActionOnClick.current.get(
          `${action.id}`
        );
        if (typeof actionOnClick === "function") {
          actionOnClick();
        } else {
          // Open the correct file picker
          mediaFilePickerRef.current!.accept = `${action.id}/*`;
          mediaFilePickerRef.current!.click();
        }
      }
      const eventName = "cc-actionsheet-clicked";
      actionSheetElement.addEventListener(eventName, handleEvent);
      return () => {
        actionSheetElement.removeEventListener(eventName, handleEvent);
      };
    },
    [
      secondaryBtnElement,
      actionSheetElement,
      dispatch,
      actionIdToActionOnClick,
      mediaFilePickerRef,
    ]
  );

  useEffect(
    /**
     * Add `cc-preview-close-clicked` event listener to the preview element
     */
    () => {
      if (!textMessageEditPreviewElement) {
        return;
      }
      function onPreviewCloseClick() {
        dispatch({ type: "setTextMessageToEdit", textMessageToEdit: null });
        // Empty the text in the message composer
        dispatch({ type: "setText", text: "" });
        textInputRef.current?.emptyInputField();
        mySetAddToMsgInputText("");
      }
      const eventName = "cc-preview-close-clicked";
      textMessageEditPreviewElement.addEventListener(
        eventName,
        onPreviewCloseClick
      );
      return () => {
        textMessageEditPreviewElement.removeEventListener(
          eventName,
          onPreviewCloseClick
        );
      };
    },
    [textMessageEditPreviewElement, dispatch, textInputRef]
  );

  useEffect(
    /**
     * Add `cc-button-clicked` event listener to the secondary button element
     */
    () => {
      if (!secondaryBtnElement) {
        return;
      }
      function onSecondaryBtnClick() {
        switch (contentToDisplay) {
          case "attachments":
            dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
            break;
          case "emojiKeyboard":
            auxiliaryBtnElement?.click();

            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "attachments",
            });
            break;
          case "voiceRecording":
            voiceRecordingBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "attachments",
            });
            break;
          case "ai":
            aiBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "attachments",
            });
            break;
          case "none":
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "attachments",
            });
            break;
          default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const x: never = contentToDisplay;
          }
        }
      }

      function onAttachmentPopoverOutsideClick() {
        dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
      }

      const eventName = "cc-button-clicked";
      const outsideClickEventName = "cc-popover-outside-clicked";
      secondaryBtnElement.addEventListener(eventName, onSecondaryBtnClick);
      attachmentPopoverElement.addEventListener(
        outsideClickEventName,
        onAttachmentPopoverOutsideClick
      );
      return () => {
        secondaryBtnElement.removeEventListener(eventName, onSecondaryBtnClick);
        attachmentPopoverElement.removeEventListener(
          outsideClickEventName,
          onAttachmentPopoverOutsideClick
        );
      };
    },
    [
      contentToDisplay,
      secondaryBtnElement,
      attachmentPopoverElement,
      auxiliaryBtnElement,
      voiceRecordingBtnElement,
      aiBtnElement,
      dispatch,
    ]
  );

  useEffect(
    /**
     * Add `cc-button-clicked` event listener to the ai button element
     */
    () => {
      if (!aiBtnElement) {
        return;
      }

      function onAIBtnClick() {
        setSmartRepliesView(null);
        switch (contentToDisplay) {
          case "ai":
            dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
            break;
          case "attachments":
            secondaryBtnElement?.click();
            dispatch({ type: "setContentToDisplay", contentToDisplay: "ai" });
            break;
          case "emojiKeyboard":
            auxiliaryBtnElement?.click();
            dispatch({ type: "setContentToDisplay", contentToDisplay: "ai" });
            break;
          case "voiceRecording":
            voiceRecordingBtnElement?.click();
            dispatch({ type: "setContentToDisplay", contentToDisplay: "ai" });
            break;
          case "none":
            dispatch({ type: "setContentToDisplay", contentToDisplay: "ai" });
            break;
          default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const x: never = contentToDisplay;
          }
        }
      }

      function onAIPopoverOutsideClick() {
        dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
      }

      const eventName = "cc-button-clicked";
      const outsideClickEventName = "cc-popover-outside-clicked";
      aiBtnElement.addEventListener(eventName, onAIBtnClick);
      aiPopoverElement.addEventListener(
        outsideClickEventName,
        onAIPopoverOutsideClick
      );
      return () => {
        aiBtnElement.removeEventListener(eventName, onAIBtnClick);
        aiPopoverElement.removeEventListener(
          outsideClickEventName,
          onAIPopoverOutsideClick
        );
      };
    },
    [
      contentToDisplay,
      aiBtnElement,
      aiPopoverElement,
      secondaryBtnElement,
      auxiliaryBtnElement,
      voiceRecordingBtnElement,
      setSmartRepliesView,
      dispatch,
    ]
  );

  useEffect(
    /**
     * Add `cc-button-clicked` event listener to the auxiliary button element
     */
    () => {
      if (!auxiliaryBtnElement || !voiceRecordingBtnElement) {
        return;
      }
      function onAuxiliaryBtnClick() {
        switch (contentToDisplay) {
          case "attachments":
            secondaryBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "emojiKeyboard",
            });
            break;
          case "voiceRecording":
            voiceRecordingBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "emojiKeyboard",
            });
            break;
          case "emojiKeyboard":
            dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
            break;
          case "ai":
            aiBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "emojiKeyboard",
            });
            break;
          case "none":
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "emojiKeyboard",
            });
            break;
          default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const x: never = contentToDisplay;
          }
        }
      }
      function onVoiceRecordingBtnClick() {
        switch (contentToDisplay) {
          case "attachments":
            secondaryBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "voiceRecording",
            });
            break;
          case "emojiKeyboard":
            auxiliaryBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "voiceRecording",
            });
            break;
          case "voiceRecording":
            dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
            break;
          case "ai":
            aiBtnElement?.click();
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "voiceRecording",
            });
            break;
          case "none":
            dispatch({
              type: "setContentToDisplay",
              contentToDisplay: "voiceRecording",
            });
            break;
          default: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const x: never = contentToDisplay;
          }
        }
      }
      const eventName = "cc-button-clicked";
      auxiliaryBtnElement.addEventListener(eventName, onAuxiliaryBtnClick);
      voiceRecordingBtnElement.addEventListener(
        eventName,
        onVoiceRecordingBtnClick
      );
      return () => {
        auxiliaryBtnElement.removeEventListener(eventName, onAuxiliaryBtnClick);
        voiceRecordingBtnElement.removeEventListener(
          eventName,
          onVoiceRecordingBtnClick
        );
      };
    },
    [
      contentToDisplay,
      secondaryBtnElement,
      voiceRecordingBtnElement,
      auxiliaryBtnElement,
      aiBtnElement,
      dispatch,
    ]
  );

  useEffect(
    /**
     * Add `cc-emoji-clicked` event listener to the emoji keyboard element
     */
    () => {
      if (!emojiKeyboardElement) {
        return;
      }
      function onEmojiClicked(e: CustomEvent) {
        const emoji = e.detail.id;
        if (typeof emoji === "string") mySetAddToMsgInputText(emoji);
      }

      function onEmojiKeyboardClose(e: CustomEvent) {
        dispatch({ type: "setContentToDisplay", contentToDisplay: "none" });
      }

      const eventName = "cc-emoji-clicked";
      const outsideClickEventName = "cc-popover-outside-clicked";
      emojiKeyboardElement.addEventListener(eventName, onEmojiClicked);
      auxiliaryPopoverElement.addEventListener(
        outsideClickEventName,
        onEmojiKeyboardClose
      );
      return () => {
        emojiKeyboardElement.removeEventListener(eventName, onEmojiClicked);
        auxiliaryPopoverElement.removeEventListener(
          outsideClickEventName,
          onEmojiKeyboardClose
        );
      };
    },
    [
      mySetAddToMsgInputText,
      auxiliaryPopoverElement,
      emojiKeyboardElement,
      dispatch,
    ]
  );

  useEffect(
    /**
     * Add `cc-media-recorder-closed` event listener to the media recorder element
     * Add `cc-media-recorder-submitted` event listener to the media recorder element
     */
    () => {
      if (!voiceRecordingElement) {
        return;
      }
      function onCloseTriggered(e: CustomEvent) {
        const customEvent = new CustomEvent("cc-button-clicked", {});
        voiceRecordingBtnElement?.click();
        voiceRecordingBtnElement?.dispatchEvent(customEvent);
      }
      function sendVoiceMessage($event: CustomEvent) {
        const blob = $event.detail.file;
        onCloseTriggered($event);
        handleSendVoiceMessage(blob);
      }
      const closeEvent = "cc-media-recorder-closed";
      const submitEvent = "cc-media-recorder-submitted";
      voiceRecordingElement.addEventListener(closeEvent, onCloseTriggered);
      voiceRecordingElement.addEventListener(submitEvent, sendVoiceMessage);
      return () => {
        voiceRecordingElement.removeEventListener(closeEvent, onCloseTriggered);
        voiceRecordingElement.removeEventListener(
          submitEvent,
          sendVoiceMessage
        );
      };
    },
    [
      voiceRecordingElement,
      voiceRecordingBtnElement,
      dispatch,
      handleSendVoiceMessage,
    ]
  );

  useEffect(
    /**
     * Add `cc-button-clicked` event listener to the primary button element
     */
    () => {
      if (!primaryBtnElement) {
        return;
      }
      function handleEvent() {
        if (text) {
          handleSendButtonClick(text);
        }
      }
      const eventName = "cc-button-clicked";
      primaryBtnElement.addEventListener(eventName, handleEvent);
      return () => {
        primaryBtnElement.removeEventListener(eventName, handleEvent);
      };
    },
    [handleSendButtonClick, text, primaryBtnElement]
  );

  useEffect(
    /**
     * Add `cc-message-input-entered` event listener to the message input element
     */
    () => {
      const textInputElement = textInputRef.current;
      if (!textInputElement) {
        return;
      }
      function onTextInputEnter(e: CustomEvent) {
        setShowListForMentions(false);
        const textToSend = e.detail.value;
        if (typeof textToSend === "string") handleSendButtonClick(textToSend);
      }
      const eventName = "cc-text-input-entered";
      textInputElement.addEventListener(eventName, onTextInputEnter);
      return () => {
        textInputElement.removeEventListener(eventName, onTextInputEnter);
      };
    },
    [handleSendButtonClick, textInputRef]
  );

  useEffect(
    /**
     * Add `cc-message-input-changed` event listener to the message input element
     */
    () => {
      const textInputElement = textInputRef.current;
      if (!textInputElement) {
        return;
      }
      function onTextInputChange(e: CustomEvent) {
        const newText = e.detail.value;
        if (typeof newText === "string" || newText == undefined) {

          handleTyping();
          dispatch({ type: "setText", text: newText });
          mySetAddToMsgInputText("")  
          if (onTextChange !== undefined) onTextChange(newText);
        }
      }
      const eventName = "cc-text-input-changed";
      textInputElement.addEventListener(eventName, onTextInputChange);
      return () => {
        textInputElement.removeEventListener(
          eventName,
          onTextInputChange
        );
      };
    },

  // Trigger the cc-text-input-changed action on chat change (guid, uid) to ensure the send button is enabled.
  [onTextChange, handleTyping, dispatch, textInputRef
      ,group?.getGuid()
      ,user?.getUid()
    ]
  );

  useEffect(
    /**
     * Subscribes to showModal & hideModal UI event to show & hide the Polls UI.
     */
    () => {
      const subShowModal = CometChatUIEvents.ccShowModal.subscribe(
        (data: IModal) => {
          dispatch({ type: "setShowPoll", showPoll: true });
          createPollViewRef.current = data.child;
        }
      );

      const subHideModal = CometChatUIEvents.ccHideModal.subscribe(() => {
        dispatch({ type: "setShowPoll", showPoll: false });
        createPollViewRef.current = null;
      });
      return () => {
        subShowModal.unsubscribe();
        subHideModal.unsubscribe();
      };
    },
    [createPollViewRef, dispatch]
  );

  useEffect(() => {
    if (!disableMentions) {
      if (textFormatters.length) {
        let foundMentionsFormatter = false;

        for (let i = 0; i < textFormatterArray.length; i++) {
          if (textFormatterArray[i] instanceof CometChatMentionsFormatter) {
            foundMentionsFormatter = true;
            break;
          }
        }

        if (!foundMentionsFormatter) {
          mentionsTextFormatterInstanceRef.current.setLoggedInUser(
            CometChatUIKitLoginListener.getLoggedInUser()
          );

          if (
            mentionsTextFormatterInstanceRef.current.getKeyDownCallBack() ===
            undefined
          ) {
            mentionsTextFormatterInstanceRef.current.setKeyDownCallBack(
              searchMentions
            );
            mentionsTextFormatterInstanceRef.current.setKeyUpCallBack(
              searchMentions
            );
          }

          setTextFormatters([
            ...textFormatterArray,
            mentionsTextFormatterInstanceRef.current,
          ]);
        }
      } else {
        mentionsTextFormatterInstanceRef.current.setLoggedInUser(
          CometChatUIKitLoginListener.getLoggedInUser()
        );

        if (
          mentionsTextFormatterInstanceRef.current.getKeyDownCallBack() ===
          undefined
        ) {
          mentionsTextFormatterInstanceRef.current.setKeyDownCallBack(
            searchMentions
          );
          mentionsTextFormatterInstanceRef.current.setKeyUpCallBack(
            searchMentions
          );
        }

        setTextFormatters([
          ...textFormatterArray,
          mentionsTextFormatterInstanceRef.current,
        ]);
      }
    }
    let mentionsTextFormatterInstance =
      mentionsTextFormatterInstanceRef.current;
    return () => {
      if (mentionsTextFormatterInstance) {
        mentionsTextFormatterInstance.cleanup();
      }
    };
  }, []);

  useEffect(() => {
    const shouldClearText =
      (userPropRef.current &&
        user &&
        userPropRef.current.getUid() !== user.getUid()) ||
      (groupPropRef.current &&
        group &&
        groupPropRef?.current.getGuid() !== group.getGuid());

    if (shouldClearText) {
      dispatch({ type: "setText", text: "" });
      mySetAddToMsgInputText("");
    }

    if (userPropRef.current) {
      setShowListForMentions(
        user && userPropRef.current.getUid() !== user.getUid()
      );
    }
    if (groupPropRef.current) {
      setShowListForMentions(
        group && groupPropRef?.current.getGuid() !== group.getGuid()
      );
    }
    for (let i = 0; i < textFormatterArray.length; i++) {
      textFormatterArray[i].setComposerConfig(user, group, getComposerId());
    }
  }, [
    user,
    group,
    userPropRef,
    groupPropRef,
    dispatch,
    textInputRef,
    mySetAddToMsgInputText,
  ]);

  useEffect(() => {
    if (!disableMentions) {
      if (group) {
        const listType =
          userMemberWrapperConfiguration?.userMemberListType !== undefined
            ? userMemberWrapperConfiguration?.userMemberListType
            : UserMemberListType.groupmembers;

        setUserMemberListType(listType);

        const requestBuilder =
          userMemberWrapperConfiguration?.groupMemberRequestBuilder
            ? userMemberWrapperConfiguration?.groupMemberRequestBuilder
            : new CometChat.GroupMembersRequestBuilder(
              group.getGuid()
            ).setLimit(15);
        setGroupMembersRequestBuilder(requestBuilder);
      }

      if (user) {
        const listType =
          userMemberWrapperConfiguration?.userMemberListType !== undefined
            ? userMemberWrapperConfiguration?.userMemberListType
            : UserMemberListType.users;

        setUserMemberListType(listType);

        const requestBuilder =
          userMemberWrapperConfiguration?.usersRequestBuilder
            ? userMemberWrapperConfiguration?.usersRequestBuilder
            : new CometChat.UsersRequestBuilder().setLimit(15);

        setUsersRequestBuilder(requestBuilder);
      }
    }
  }, [user, group, disableMentions]);

  useEffect(() => {
    const handleMouseDown = (event: { target: any }) => {
      if (
        userMemberWrapperRef.current &&
        !userMemberWrapperRef.current.contains(event.target)
      ) {
        setShowListForMentions(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);
  // Reset composer when user/group changes.
  useEffect(() => {
    try {
      setTimeout(() => {
        if (isPreviewVisible.current && textInputRef.current) {
          dispatch({ type: "setTextMessageToEdit", textMessageToEdit: null });
          dispatch({ type: "setText", text: "" });
          textInputRef.current?.emptyInputField();
          mySetAddToMsgInputText("");
          isPreviewVisible.current = false;
        }
      });

    }
    catch (error) {
      errorHandler(error);
    }

  }, [user, group, parentMessageIdPropRef
  ]);
}
