import React from 'react';
import { Box, Button, Grid, Popover } from '@mui/material';
import PropTypes from 'prop-types';
import PopupTitle from './popup_title';

const UnsavedChangesWarningPopover = ({
  anchorEl,
  onConfirmClose,
  onDismiss,
}) => {
  console.log({ anchorEl });

  const popoverPropsNoAnchor = {
    anchorReference: 'anchorPosition',
    anchorPosition: {
      top: window.innerHeight / 2,
      left: window.innerWidth / 2,
    },
    anchorOrigin: { horizontal: 'center', vertical: 'center' },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'center',
    },
  };

  const popoverPropsWithAnchor = {
    anchorEl,
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
  };

  const popoverProps = anchorEl ? popoverPropsWithAnchor : popoverPropsNoAnchor;

  return (
    <Popover
      open
      PaperProps={{
        style: { width: `320px`, height: `140px`, padding: '10px' },
      }}
      onClose={onDismiss}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...popoverProps}>
      <Grid container>
        <Grid item xs={12}>
          <PopupTitle
            closeButtonEnabled
            onClickClose={onDismiss}
            titleText="Close"
          />
        </Grid>
        <Grid item xs={12} style={{ padding: '5px' }}>
          You have unsaved changes. Are you sure you want to leave this page?
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Button
            onClick={(ev) => {
              onConfirmClose();
              ev.stopPropagation();
            }}
            variant="contained"
            color="secondary"
            size="medium"
            disableElevation
            style={{ justifyContent: 'flex-start', color: 'red' }}>
            Close
          </Button>
        </Grid>
        <Grid item xs={6} style={{ padding: '5px' }}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={(ev) => {
                onDismiss();
                ev.stopPropagation();
              }}
              variant="contained"
              color="secondary"
              size="medium"
              disableElevation
              style={{ justifyContent: 'flex-end' }}>
              Stay on page
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

UnsavedChangesWarningPopover.propTypes = {
  anchorEl: PropTypes.element,
  onConfirmClose: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

UnsavedChangesWarningPopover.defaultProps = {
  anchorEl: null,
};

export default UnsavedChangesWarningPopover;
