import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import boardPlaceholderCollection from '../../assets/images/boardPlaceholderCollection.png';
import boardPlaceholderFavourite from '../../assets/images/boardPlaceholderFavourite.png';

const RecipeCollectionCard = ({
  boardID,
  title,
  coverImageUrl,
  onClickBoard,
  isFavourite,
}) => {
  const actualWidth = 166;

  const theme = useTheme();

  const boardPlaceholder = isFavourite
    ? boardPlaceholderFavourite
    : boardPlaceholderCollection;

  return (
    <div style={{ position: 'relative' }}>
      <Card
        onClick={(ev) => {
          ev.preventDefault();
          onClickBoard(boardID);
        }}
        className="shared-programme-card"
        sx={{
          width: actualWidth,
          height: 192,
          minWidth: actualWidth,
          cursor: 'pointer',
        }}>
        <div style={{ position: 'relative' }}>
          <CardMedia
            sx={{ height: actualWidth - 26 }}
            style={{ backgroundColor: theme.palette.primary.cardBackground }}
            image={coverImageUrl || boardPlaceholder}
          />
        </div>
        <CardContent sx={{ padding: '8px !important' }}>
          <Typography
            variant="h4"
            sx={{ padding: 0 }}
            style={{ fontSize: '14px' }}>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

RecipeCollectionCard.propTypes = {
  boardID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string.isRequired,
  onClickBoard: PropTypes.string.isRequired,
  isFavourite: PropTypes.bool.isRequired,
};

export default RecipeCollectionCard;
