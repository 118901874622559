import isInt from 'validator/lib/isInt';

export const isURL = (s) => {
  let url;

  try {
    url = new URL(s);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isStringOrNumberInt = (stringOrNumber, constraints) => {
  if (typeof stringOrNumber === 'string') {
    return isInt(stringOrNumber, constraints);
  }
  // dumb
  return isInt(stringOrNumber.toString(), constraints);
};

export const urlDisplayableHostName = (s) => {
  if (!s) {
    return '';
  }
  try {
    const rawHostName = new URL(s).hostname;
    if (rawHostName.startsWith('www.')) {
      return rawHostName.substring(4);
    }
    return rawHostName;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const capitalizeInitialLetter = (s) =>
  s.charAt(0).toUpperCase() + s.slice(1);

export const escapeToHtmlEntities = (str) => {
  return str.replace(/[&<>"']/g, (char) => {
    switch (char) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      case "'":
        return '&#39;';
      default:
        return char;
    }
  });
};
