import React, { useState } from 'react';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { updateSpaceAction } from '../../action_creators/spaces_action_creators';
import ImageUploadIconButton from '../common/image_upload_icon_button';
import { uploadUserImage } from '../../services/user_generated_content';
import AppSplashPagePreview from './app_splash_page_preview';

const SpaceSplashPageEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);

  const navigate = useNavigate();

  const [whiteLabelElements, setWhiteLabelElements] = useState(
    space?.whiteLabelElements || {},
  );

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        whiteLabelElements,
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const onNewImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(space.id, fileList);
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      splashImageUrl: newImageUrl,
    }));
    afterChange();
  };

  const afterChange = () => {
    setIsDirty(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  return (
    <div className="userProfile">
      <div className="banner">
        <IconButton
          aria-label="close"
          onClick={onGoBack}
          size="small"
          style={{ float: 'right', marginTop: -7 }}>
          <CloseIcon />
        </IconButton>
        Edit your splash page
      </div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h5>Customise your app theme</h5>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              Use the controls below to customise the app in your colours. The
              preview on the right shows what your app would look like.
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Upload your splash screen</h5>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              We recommend an image resolution of 428 by 926 pixels, and we
              support png and jpg.
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <ImageUploadIconButton onChange={onNewImageChosen} />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!isDirty || isLoading}>
              Save
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20, height: 250 }}>
            {/* Spacer */}
            &nbsp;
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <AppSplashPagePreview
            backgroundStyle={whiteLabelElements?.backgroundStyle}
            backgroundColor={whiteLabelElements?.backgroundColor}
            backgroundGradientColor={
              whiteLabelElements?.backgroundGradientColor
            }
            splashImageUrl={whiteLabelElements?.splashImageUrl}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SpaceSplashPageEditForm;
