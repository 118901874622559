import { API, Auth, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import { getInputForUpdate } from './utils';
import { PROGRAMMES_STUDIO_FEATURE_CODE } from '../services/programmes';

export const createUserProfileOperation = async (input) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createUserProfile, { input }),
  );
  return response.data.createUserProfile;
};

export const getUserProfileOperation = async () => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.listUserProfiles, { nextToken }),
    );
    return response.data.listUserProfiles;
  };

  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    if (items.length > 0) {
      return items[0];
    }
  } while (currentToken !== null);

  return null;
};

export const createOrGetExistingUserProfileOperation = async () => {
  const userProfile = await getUserProfileOperation();
  if (!userProfile) {
    const authUser = await Auth.currentAuthenticatedUser();
    const memberInCognitoGroups =
      authUser.signInUserSession.accessToken.payload['cognito:groups'] || [];
    const isCreator = memberInCognitoGroups.length > 0;
    const userProfileDoc = {};
    if (isCreator) {
      userProfileDoc.requestedFeatures = [PROGRAMMES_STUDIO_FEATURE_CODE];
      userProfileDoc.enabledFeatures = [PROGRAMMES_STUDIO_FEATURE_CODE];
    }
    return createUserProfileOperation(userProfileDoc);
  }
  return userProfile;
};

export const updateUserProfileOperation = async (userProfile) => {
  const input = getInputForUpdate(userProfile);
  const updateUserProfileResponse = await API.graphql(
    graphqlOperation(mutations.updateUserProfile, { input }),
  );
  console.log({ updateUserProfileResponse });
  return updateUserProfileResponse.data.updateUserProfile;
};

export const loadGlobalTagsOperation = async () => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.listGlobalTags, { nextToken }),
    );
    return response.data.listGlobalTags;
  };

  const globalTags = [];
  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    globalTags.push(...items);
  } while (currentToken !== null);

  return globalTags;
};

export const sendRequestFeatureNotificationOperation = async (
  userId,
  userEmail,
  featureCode,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.adminEmailUserRequestedFeature, {
      userId,
      userEmail,
      featureCode,
    }),
  );
  return response.data.adminEmailUserRequestedFeature;
};

export const sendAccountDeletedNotificationOperation = async (
  userId,
  userEmail,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.adminEmailUserDeleted, {
      userId,
      userEmail,
    }),
  );
  return response.data.adminEmailUserDeleted;
};

export const getIngredientTreeNodesOperation = async (locale) => {
  const response = await API.graphql({
    ...graphqlOperation(mutations.getIngredientTreeNodes, { locale }),
    authMode: 'AWS_IAM',
  });
  return response.data.getIngredientTreeNodes;
};

export const getSpaceMembershipsOperation = async (userId) => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.spaceMembershipByMemberID, {
        memberID: userId,
        nextToken,
      }),
    );
    return response.data.spaceMembershipByMemberID;
  };

  const spaceMemberships = [];
  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    spaceMemberships.push(...items);
  } while (currentToken !== null);

  return spaceMemberships;
};

export const syncSpaceMembershipOperation = async (
  spaceMembershipID,
  onboardingAnswers,
  onboardingAnswersLastUpdatedAt,
  customTargetCalories,
  welcomeVideoWatched,
  initialProgrammeSelectionLastOfferedAt,
  chatNickname,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.syncSpaceMembership, {
      spaceMembershipID,
      onboardingAnswers,
      onboardingAnswersLastUpdatedAt,
      customTargetCalories,
      welcomeVideoWatched,
      initialProgrammeSelectionLastOfferedAt,
      chatNickname,
    }),
  );
  return response.data.syncSpaceMembership;
};

export const getPublicSpaceOperation = async (spaceID) => {
  const response = await API.graphql({
    ...graphqlOperation(
      `
    query GetSpace($id: ID!) {
      getSpace(id: $id) {
        title
        shortDescription
        whiteLabelElements {
          primaryMainColor
          backgroundStyle
          backgroundColor
          backgroundGradientColor
          iconUrl
          splashImageUrl
          myDayBackgroundImageUrl
          cardBackgroundColor
          desktopBannerImageUrl
          termsUrl
          privacyPolicyUrl
        }
        landingPageContent
        landingPageStyle {
          backgroundColor
        }
        landingPageCoverImageUrl
        sharedProgrammeConfiguration {
          sharedProgrammeCategories {
            categoryTag
            orderedProgrammeIDs
          }
        }
        productPreviewSections {
          title
          productPreviews {
            productType
            productObjectID
            title
            shortDescription
            coverImageUrl
            availableInMembershipTierIDs
          }
        }
        spaceContentBoard {
          id
          title
          lanes {
            id
            title
            entries {
              id
              entryType
              objectID
            }
          }
        }
        welcomeVideo {
          videoID
          title
          description
          thumbnailImageUrl
        }
        myDayScreenSections {
          publishedSectionType
          spaceContentLaneID
        }
        chatConfig {
          appID
          appRegion
        }
        appStoreLinks {
          appStoreID
          appUrl
        }
        sharedRecipesBoardIDs
        defaultSharedProgrammeIDForNewUsers
        subscriptionBenefitsContent
        subscriptionPageStyle {
          backgroundColor
        }
        id
        createdAt
        updatedAt
        owner
      }
    }
  `,
      { id: spaceID },
    ),
    authMode: 'AWS_IAM',
  });
  return response.data.getSpace;
};

export const createSimpleSpaceMembershipOperation = async (
  spaceID,
  onboardingAnswers,
  referrer,
) => {
  const doc = { spaceID, referrer };
  if (onboardingAnswers && onboardingAnswers.length > 0) {
    doc.onboardingAnswers = onboardingAnswers;
    doc.onboardingAnswersLastUpdatedAt = new Date().toISOString();
  }
  const response = await API.graphql(
    graphqlOperation(mutations.createMySpaceMembership, doc),
  );
  return response.data.createMySpaceMembership;
};

export const createPreactivatedSpaceMembershipOperation = async (
  spaceID,
  referrer,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createMySpaceMembership, {
      spaceID,
      isPreactivated: true,
      referrer,
    }),
  );
  return response.data.createMySpaceMembership;
};

export const updateMembershipWithAppleIAPOperation = async (
  spaceMembershipID,
  appStoreTransactionReceipt,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.updateMembershipWithAppleIAP, {
      spaceMembershipID,
      appStoreTransactionReceipt,
    }),
  );
  return response.data.updateMembershipWithAppleIAP;
};

export const updateMembershipWithGooglePlayPurchaseOperation = async (
  spaceMembershipID,
  googlePlayTransactionReceipt,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.updateMembershipWithGooglePlayPurchase, {
      spaceMembershipID,
      googlePlayTransactionReceipt,
    }),
  );
  return response.data.updateMembershipWithGooglePlayPurchase;
};

export const updateMembershipNoTierAvailableOperation = async (
  spaceMembershipID,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.updateMembershipNoTierAvailable, {
      spaceMembershipID,
    }),
  );
  return response.data.updateMembershipNoTierAvailable;
};

export const getSharedProgrammesOperation = async (spaceID) => {
  const fetchPage = async (nextToken) => {
    const response = await API.graphql(
      graphqlOperation(queries.sharedProgrammeBySpace, {
        spaceID,
        nextToken,
      }),
    );
    return response.data.sharedProgrammeBySpace;
  };

  const sharedProgrammes = [];
  let currentToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const { items, nextToken } = await fetchPage(currentToken);
    currentToken = nextToken;
    sharedProgrammes.push(...items);
  } while (currentToken !== null);

  return sharedProgrammes;
};

export const getSharedProgrammeOperation = async (sharedProgrammeID) => {
  const response = await API.graphql(
    graphqlOperation(queries.getSharedProgramme, { id: sharedProgrammeID }),
  );
  return response.data.getSharedProgramme;
};

export const createStripeCheckoutSessionOperation = async (
  membershipTierID,
  spaceMembershipID,
  returnUrl,
  duration,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.createStripeCheckoutSession, {
      membershipTierID,
      spaceMembershipID,
      returnUrl,
      duration,
    }),
  );
  return response.data.createStripeCheckoutSession;
};

export const checkStripeCheckoutSessionOperation = async (
  spaceMembershipID,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.checkStripeCheckoutSession, {
      spaceMembershipID,
    }),
  );
  return response.data.checkStripeCheckoutSession;
};

export const emailWelcomeToSpaceMessageOperation = async (
  recipient,
  givenName,
  familyName,
  spaceTitle,
  spaceSubscribeUrl,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.emailWelcomeToSpaceMessage, {
      recipient,
      givenName,
      familyName,
      spaceTitle,
      spaceSubscribeUrl,
    }),
  );
  return response.data.emailWelcomeToSpaceMessage;
};

export const cancelSubscriptionOperation = async (spaceMembershipID) => {
  const response = await API.graphql(
    graphqlOperation(mutations.cancelSubscription, {
      spaceMembershipID,
    }),
  );
  return response.data.cancelSubscription;
};

const SIGNALS_EXPIRY_MINUTES = 15;

export const getLatestAccountActivitySignalsOperation = async (spaceID) => {
  const nowStr = new Date().toISOString();
  const oldestSignalTimestampStr = dayjs(nowStr)
    .subtract(SIGNALS_EXPIRY_MINUTES, 'minute')
    .toISOString();
  const signals = [];
  const fetchPage = async (nextToken) => {
    const signalsResponse = await API.graphql(
      graphqlOperation(queries.accountActivitySignalBySpaceID, {
        spaceID,
        lastActivityAt: { gt: oldestSignalTimestampStr },
        nextToken,
      }),
    );
    const signalsPage =
      signalsResponse.data?.accountActivitySignalBySpaceID?.items || [];
    signals.push(...signalsPage);
    return signalsResponse.data?.accountActivitySignalBySpaceID?.nextToken;
  };

  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    nextToken = await fetchPage(nextToken);
  } while (nextToken !== null);

  return signals;
};

export const pingRecipesServiceOperation = async () => {
  const response = await API.graphql(
    graphqlOperation(mutations.pingRecipesService, {}),
  );
  return response.data.pingRecipesService;
};

export const reportAccountActivitySignalOperation = async (
  accountActivitySignal,
) => {
  const response = await API.graphql(
    graphqlOperation(mutations.reportAccountActivitySignal, {
      accountActivitySignal,
    }),
  );
  return response.data.reportAccountActivitySignal;
};
