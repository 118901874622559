import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  MenuItem,
  Select,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import signup_teaser from '../../assets/images/signup_teaser_animation_2.gif';
import { REMINDER_SCHEDULES } from '../../services/meal_plan_reminders';
import image_step_reminders from '../../assets/images/onboarding/step_reminders.png';
import {
  currentEndUserSpaceSelector,
  isUserSpaceMembershipDefaultSpaceSelector,
} from '../../reducers/user_reducer';

const OnboardingStepReminders = ({
  mealPlanReminderSchedule,
  setMealPlanReminderSchedule,
  onNext,
  onBack,
  isLoading,
  disableTeaser,
}) => {
  const isCommunityUser = useSelector(
    isUserSpaceMembershipDefaultSpaceSelector,
  );

  const myDayBackgroundImageUrl = useSelector(
    (state) =>
      currentEndUserSpaceSelector(state)?.whiteLabelElements
        ?.myDayBackgroundImageUrl,
  );

  const shouldUseSpaceImages = !isCommunityUser;

  return (
    <main className="authForm">
      {!disableTeaser && (
        <img src={signup_teaser} className="authTeaser" alt="Are you ready?" />
      )}
      <Dialog
        open
        maxWidth="md"
        fullWidth
        BackdropProps={{ invisible: true }}
        className="authDialog signupDialog onboardingStep">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <DialogContent>
              <div className="wizardStep">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    onBack();
                  }}>
                  <FontAwesomeIcon icon="chevron-left" />
                </a>
                &nbsp; Step 3 of 3
              </div>
              <h2>Set up reminders</h2>
              <div>We can send you a weekly reminder to plan your meals.</div>
              <Grid container columnSpacing={1}>
                <Grid item xs={12}>
                  <DialogContentText>
                    What day do you plan your meals?
                  </DialogContentText>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    variant="standard"
                    fullWidth
                    displayEmpty
                    disabled={isLoading}
                    value={mealPlanReminderSchedule}
                    onChange={(ev) => {
                      const newScheduleName = ev.target.value;
                      setMealPlanReminderSchedule(newScheduleName);
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem disabled value="">
                      <em>Select a schedule</em>
                    </MenuItem>
                    {REMINDER_SCHEDULES.map((schedule) => (
                      <MenuItem key={schedule.name} value={schedule.name}>
                        {schedule.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{ margin: 'auto 10px', padding: '6px' }}>
                  <Button
                    variant="contained"
                    onClick={onNext}
                    disabled={isLoading}>
                    Next
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
          <Grid item sm={6} className="decorationImage">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              style={{
                maxWidth: '424px',
                maxHeight: '424px',
                margin: 'auto 0 auto auto',
              }}
              src={
                shouldUseSpaceImages
                  ? myDayBackgroundImageUrl
                  : image_step_reminders
              }
            />
          </Grid>
        </Grid>
      </Dialog>
    </main>
  );
};

OnboardingStepReminders.propTypes = {
  mealPlanReminderSchedule: PropTypes.string,
  setMealPlanReminderSchedule: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  disableTeaser: PropTypes.bool,
};

OnboardingStepReminders.defaultProps = {
  mealPlanReminderSchedule: null,
  disableTeaser: false,
};

export default OnboardingStepReminders;
