import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import smorg_logo from '../../assets/images/logo.png';
import smorg_studio_logo from '../../assets/images/logo_smorg_studio_larger.png';
import { spaceIDFromAppUrl } from '../../services/spaces';

const AuthHeader = ({ isForCreators }) => {
  const imgStyle = isForCreators ? { width: 242 } : {};
  const intoSpaceID = spaceIDFromAppUrl();
  const shouldShowImage = isForCreators || !intoSpaceID;
  return (
    shouldShowImage && (
      <>
        <img
          src={isForCreators ? smorg_studio_logo : smorg_logo}
          className="logo-image"
          alt="Smorg"
          style={imgStyle}
        />
        {!isForCreators && (
          <>
            <br />
            <Typography variant="caption">Live cool</Typography>
          </>
        )}
      </>
    )
  );
};

AuthHeader.propTypes = {
  isForCreators: PropTypes.bool,
};

AuthHeader.defaultProps = {
  isForCreators: false,
};

export default AuthHeader;
