import React, { useState } from 'react';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TwitterPicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';
import {
  COLOR_PICKER_BACKGROUND_COLORS,
  COLOR_PICKER_PRIMARY_MAIN_COLORS,
  DEFAULT_SMORG_BACKGROUND_COLOR,
  DEFAULT_SMORG_PRIMARY_MAIN_COLOR,
} from '../../services/spaces';
import { updateSpaceAction } from '../../action_creators/spaces_action_creators';
import AppThemePreview from './app_theme_preview';
import ColorIndicator from '../common/color_indicator';
import { BackgroundStyle } from '../../API';
import ImageUploadIconButton from '../common/image_upload_icon_button';
import { uploadUserImage } from '../../services/user_generated_content';
// import UnsavedChangesWarningPopover from '../common/unsaved_changes_warning_popover';

const SpaceThemeEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);

  const navigate = useNavigate();

  const [whiteLabelElements, setWhiteLabelElements] = useState(
    space?.whiteLabelElements || {},
  );

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onBackgroundColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundColor: color.hex,
    }));
    setBackgroundColorPickerAnchorEl(null);
    afterChange();
  };

  const onBackgroundGradientColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundGradientColor: color.hex,
    }));
    setBackgroundGradientColorPickerAnchorEl(null);
    afterChange();
  };

  const onBackgroundStyleChosen = (backgroundStyle) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      backgroundStyle,
    }));
    afterChange();
  };

  const onPrimaryMainColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      primaryMainColor: color.hex,
    }));
    setPrimaryMainColorPickerAnchorEl(null);
    afterChange();
  };

  const onCardBackgroundColorChosen = (color) => {
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      cardBackgroundColor: color.hex,
    }));
    setCardBackgroundColorPickerAnchorEl(null);
    afterChange();
  };

  const [backgroundColorPickerAnchorEl, setBackgroundColorPickerAnchorEl] =
    useState(null);

  const [
    backgroundGradientColorPickerAnchorEl,
    setBackgroundGradientColorPickerAnchorEl,
  ] = useState(null);

  const [primaryMainColorPickerAnchorEl, setPrimaryMainColorPickerAnchorEl] =
    useState(null);

  const [
    cardBackgroundColorPickerAnchorEl,
    setCardBackgroundColorPickerAnchorEl,
  ] = useState(null);

  const backgroundColor =
    whiteLabelElements?.backgroundColor || DEFAULT_SMORG_BACKGROUND_COLOR;

  const backgroundGradientColor =
    whiteLabelElements?.backgroundGradientColor ||
    backgroundColor ||
    DEFAULT_SMORG_BACKGROUND_COLOR;

  const primaryMainColor =
    whiteLabelElements?.primaryMainColor || DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const cardBackgroundColor =
    whiteLabelElements?.cardBackgroundColor || DEFAULT_SMORG_PRIMARY_MAIN_COLOR;

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        whiteLabelElements,
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  const onNewBackgroundImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(space.id, fileList, true);
    setWhiteLabelElements((oldWhiteLabelElements) => ({
      ...oldWhiteLabelElements,
      myDayBackgroundImageUrl: newImageUrl,
    }));
    afterChange();
  };

  // const [unsavedChangesWarningVisible, setUnsavedChangesWarningVisible] =
  //   useState(false);

  // const [unsavedChangesWarningAnchorEl, setUnsavedChangesWarningAnchorEl] =
  //   useState(null);

  // const onConfirmClose = () => {
  //   onGoBack();
  // };

  // const onDialogClose = (event, isClickAway) => {
  //   console.log({ isDirty });
  //   if (isDirty) {
  //     event.preventDefault();
  //     setUnsavedChangesWarningVisible(true);
  //     setUnsavedChangesWarningAnchorEl(isClickAway ? null : event.target);
  //   } else {
  //     onConfirmClose();
  //   }
  // };

  // const onDismissUnsavedChangesWarningPopover = () => {
  //   setUnsavedChangesWarningAnchorEl(null);
  //   setUnsavedChangesWarningVisible(false);
  // };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  return (
    <div className="userProfile">
      <div className="banner">
        <IconButton
          aria-label="close"
          onClick={onGoBack}
          size="small"
          style={{ float: 'right', marginTop: -7 }}>
          <CloseIcon />
        </IconButton>
        Edit your theme
      </div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <h5>Customise your app theme</h5>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              Use the controls below to customise the app in your colours. The
              preview on the right shows what your app would look like.
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Define your background colour</h5>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={
                  whiteLabelElements?.backgroundStyle || BackgroundStyle.SOLID
                }
                onChange={(ev) => {
                  onBackgroundStyleChosen(ev.target.value);
                }}>
                <FormControlLabel
                  value={BackgroundStyle.SOLID}
                  control={<Radio />}
                  label="Solid"
                />
                <FormControlLabel
                  value={BackgroundStyle.LINEAR_GRADIENT}
                  control={<Radio />}
                  label="Gradient"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <div>
                <ColorIndicator
                  color={backgroundColor}
                  onClick={(ev) => {
                    setBackgroundColorPickerAnchorEl(ev.target);
                  }}
                />
                {whiteLabelElements?.backgroundStyle ===
                  BackgroundStyle.LINEAR_GRADIENT && (
                  <ColorIndicator
                    color={backgroundGradientColor}
                    onClick={(ev) => {
                      setBackgroundGradientColorPickerAnchorEl(ev.target);
                    }}
                  />
                )}
                {!!backgroundColorPickerAnchorEl && (
                  <Popover
                    open
                    anchorEl={backgroundColorPickerAnchorEl}
                    onClose={() => setBackgroundColorPickerAnchorEl(false)}>
                    <TwitterPicker
                      colors={COLOR_PICKER_BACKGROUND_COLORS}
                      color={backgroundColor}
                      onChangeComplete={onBackgroundColorChosen}
                      title="Pick a color"
                    />
                  </Popover>
                )}
                {!!backgroundGradientColorPickerAnchorEl && (
                  <Popover
                    open
                    anchorEl={backgroundGradientColorPickerAnchorEl}
                    onClose={() =>
                      setBackgroundGradientColorPickerAnchorEl(false)
                    }>
                    <TwitterPicker
                      colors={COLOR_PICKER_BACKGROUND_COLORS}
                      color={backgroundGradientColor}
                      onChangeComplete={onBackgroundGradientColorChosen}
                      title="Pick a color"
                    />
                  </Popover>
                )}
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Define your navbar colour</h5>
            </Grid>
            <Grid item xs={12}>
              <div>
                <ColorIndicator
                  color={primaryMainColor}
                  onClick={(ev) => {
                    setPrimaryMainColorPickerAnchorEl(ev.target);
                  }}
                />
                {!!primaryMainColorPickerAnchorEl && (
                  <Popover
                    open
                    anchorEl={primaryMainColorPickerAnchorEl}
                    onClose={() => setPrimaryMainColorPickerAnchorEl(false)}>
                    <TwitterPicker
                      colors={COLOR_PICKER_PRIMARY_MAIN_COLORS}
                      color={primaryMainColor}
                      onChangeComplete={onPrimaryMainColorChosen}
                      title="Pick a color"
                    />
                  </Popover>
                )}
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Define your card colour</h5>
            </Grid>
            <Grid item xs={12}>
              <div>
                <ColorIndicator
                  color={cardBackgroundColor}
                  onClick={(ev) => {
                    setCardBackgroundColorPickerAnchorEl(ev.target);
                  }}
                />
                {!!cardBackgroundColorPickerAnchorEl && (
                  <Popover
                    open
                    anchorEl={cardBackgroundColorPickerAnchorEl}
                    onClose={() => setCardBackgroundColorPickerAnchorEl(false)}>
                    <TwitterPicker
                      colors={COLOR_PICKER_PRIMARY_MAIN_COLORS}
                      color={cardBackgroundColor}
                      onChangeComplete={onCardBackgroundColorChosen}
                      title="Pick a color"
                    />
                  </Popover>
                )}
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Upload a background image</h5>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              We recommend an image resolution of 428 by 500 pixels, and we
              support png and jpg.
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <ImageUploadIconButton onChange={onNewBackgroundImageChosen} />
            </Grid>
            <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!isDirty || isLoading}>
                Save
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, height: 200 }}>
              {/* Spacer */}
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <AppThemePreview
            backgroundStyle={whiteLabelElements?.backgroundStyle}
            backgroundColor={backgroundColor}
            backgroundGradientColor={backgroundGradientColor}
            primaryMainColor={whiteLabelElements?.primaryMainColor}
            spaceTitle={space.title}
            myDayBackgroundImageUrl={
              whiteLabelElements?.myDayBackgroundImageUrl
            }
            cardBackgroundColor={cardBackgroundColor}
          />
        </Grid>
      </Grid>
      {/* {unsavedChangesWarningVisible && (
        <UnsavedChangesWarningPopover
          anchorEl={unsavedChangesWarningAnchorEl}
          onConfirmClose={onConfirmClose}
          onDismiss={onDismissUnsavedChangesWarningPopover}
        />
      )} */}
    </div>
  );
};

export default SpaceThemeEditForm;
