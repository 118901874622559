import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import MyDayRecipeCollectionCard from './my_day_recipe_collection_card';
import { boardThumbnailImageUrlSelector } from '../../reducers/recipes_reducer';

const MyDayRecipeCollectionItem = ({
  boardID,
  title,
  firstMealID,
  onClickBoard,
  isFavourite,
}) => {
  const boardCoverImageUrl = useSelector((state) =>
    boardThumbnailImageUrlSelector(state, boardID, firstMealID),
  );

  return (
    <MyDayRecipeCollectionCard
      boardID={boardID}
      title={title}
      coverImageUrl={boardCoverImageUrl}
      onClickBoard={onClickBoard}
      isFavourite={isFavourite}
    />
  );
};

MyDayRecipeCollectionItem.propTypes = {
  boardID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  firstMealID: PropTypes.string,
  onClickBoard: PropTypes.func.isRequired,
  isFavourite: PropTypes.bool.isRequired,
};

MyDayRecipeCollectionItem.defaultProps = {
  firstMealID: null,
};

export default MyDayRecipeCollectionItem;
