import React, { useState } from 'react';
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { Button, Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { updateSpaceAction } from '../../action_creators/spaces_action_creators';
import ImageUploadIconButton from '../common/image_upload_icon_button';
import { uploadUserImage } from '../../services/user_generated_content';

const SpaceDesktopBannerImageEditForm = () => {
  const space = useSelector((state) => (state.spaces || [])[0]);

  const navigate = useNavigate();

  const [desktopBannerImageUrl, setDesktopBannerImageUrl] = useState(
    space?.whiteLabelElements?.desktopBannerImageUrl,
  );

  const [isDirty, setIsDirty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const updatedSpace = {
        ...space,
        whiteLabelElements: {
          ...(space?.whiteLabelElements || {}),
          desktopBannerImageUrl,
        },
      };
      await dispatch(updateSpaceAction(updatedSpace));
      dispatch({
        type: 'SET_GLOBAL_SNACKBAR',
        notificationText: 'Changes saved',
      });
      setIsDirty(false);
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  useBlocker(() => {
    if (isDirty) {
      // eslint-disable-next-line no-alert
      return !window.confirm(
        'You have unsaved changes. Are you sure you want to leave this page?',
      );
    }
    return false; // Allow navigation
  });

  const onNewBackgroundImageChosen = async (fileList) => {
    const newImageUrl = await uploadUserImage(space.id, fileList, true);
    setDesktopBannerImageUrl(newImageUrl);
    afterChange();
  };

  return (
    <div className="userProfile">
      <div className="banner">
        <IconButton
          aria-label="close"
          onClick={onGoBack}
          size="small"
          style={{ float: 'right', marginTop: -7 }}>
          <CloseIcon />
        </IconButton>
        Set up desktop banner
      </div>
      <Grid container columnSpacing={1} className="userProfileForm">
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <h5>Upload a background image</h5>
            </Grid>
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              Upload a banner image which will be displayed for desktop layouts.
              Recommended size is 1280x260px.
            </Grid>
            {desktopBannerImageUrl && (
              <div
                style={{
                  width: '512px',
                  height: '104px',
                  backgroundImage: `url("${desktopBannerImageUrl}")`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '0% 50%',
                }}>
                &nbsp;
              </div>
            )}
            <Grid item xs={12} style={{ padding: '16px 0' }}>
              <ImageUploadIconButton onChange={onNewBackgroundImageChosen} />
            </Grid>
            <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!isDirty || isLoading}>
                Save
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, height: 200 }}>
              {/* Spacer */}
              &nbsp;
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SpaceDesktopBannerImageEditForm;
