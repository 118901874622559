import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import ImageUploadButton from '../meal/image_upload_button';
import imgPlaceholder from '../../assets/images/imgPlaceholder.png';
import {
  IProgrammesState,
  updatePlanAction,
} from '../../action_creators/programmes_action_creators';
import { ProgrammePlan } from '../../API';
import { uploadUserImage } from '../../services/user_generated_content';

interface EditPlanFormProps {
  programmeId: string;
  plan: ProgrammePlan;
  isDirty: boolean;
  setIsDirty: (dirty: boolean) => void;
}

const EditPlanForm = ({
  programmeId,
  plan,
  isDirty,
  setIsDirty,
}: EditPlanFormProps) => {
  const [imageObjectId] = useState(uuidv4());
  const [title, setTitle] = useState(plan.title);
  const [imageUrl, setImageUrl] = useState(plan.coverImageUrl);
  const [shortDescription, setShortDescription] = useState(
    plan.shortDescription,
  );
  const [description, setDescription] = useState(plan.description);
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const dispatch: ThunkDispatch<IProgrammesState, void, AnyAction> =
    useDispatch();

  const navigate = useNavigate();

  const onNewImageChosen = async (fileList: FileList) => {
    const newImageUrl = await uploadUserImage(imageObjectId, fileList);
    setImageUrl(newImageUrl);
    afterChange();
  };

  const handleSave = async () => {
    if (!title) {
      setErrorMessage('Type a name for the plan');
      return;
    }
    if (!shortDescription) {
      setErrorMessage('Type a short description for the plan');
      return;
    }
    try {
      setErrorMessage('');
      setIsLoading(true);
      dispatch(
        updatePlanAction(
          programmeId,
          plan.id,
          {
            title,
            coverImageUrl: imageUrl,
            shortDescription,
            description,
          },
          () => navigate(`/programmes/${programmeId}/plans/${plan.id}`),
        ),
      );
    } catch (err) {
      console.log({ err });
    } finally {
      setIsLoading(false);
    }
  };

  const afterChange = () => {
    setIsDirty(true);
    setErrorMessage('');
  };

  return (
    <div className="newProgramme">
      <Grid container columnSpacing={1} className="newProgrammeForm">
        <Grid item xs={12}>
          <h5>Plan name</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={title}
            onChange={(ev) => {
              setTitle(ev.target.value);
              afterChange();
            }}
            autoFocus
            margin="dense"
            label="e.g. First week"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Plan cover image</h5>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="mealDetailImage"
              style={{
                position: 'relative',
                backgroundImage: `url("${imageUrl || imgPlaceholder}")`,
              }}>
              <ImageUploadButton
                style={{ position: 'absolute', bottom: 10, right: 10 }}
                onChange={onNewImageChosen}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h5>Short description</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={shortDescription}
            onChange={(ev) => {
              setShortDescription(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Add a short description"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <h5>Long description</h5>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={description}
            onChange={(ev) => {
              setDescription(ev.target.value);
              afterChange();
            }}
            margin="dense"
            label="Add a long description"
            type="text"
            fullWidth
            size="small"
            variant="outlined"
            disabled={isLoading}
          />
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <div style={{ paddingTop: '5px', color: 'red' }}>
              {errorMessage}
            </div>
          </Grid>
        )}
        <Grid item xs={12} style={{ margin: '15px 0', padding: '6px' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={!isDirty || isLoading}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditPlanForm;
