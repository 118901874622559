import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, useTheme } from '@mui/material';
import boardPlaceholderCollection from '../../assets/images/boardPlaceholderCollection.png';
import boardPlaceholderFavourite from '../../assets/images/boardPlaceholderFavourite.png';

const MyDayRecipeCollectionCard = ({
  boardID,
  title,
  coverImageUrl,
  onClickBoard,
  isFavourite,
}) => {
  const theme = useTheme();

  const boardPlaceholder = isFavourite
    ? boardPlaceholderFavourite
    : boardPlaceholderCollection;

  return (
    <div style={{ position: 'relative' }}>
      <Card
        className="my-day-compact-content-card"
        style={{ backgroundColor: theme.palette.primary.cardBackground }}
        onClick={(ev) => {
          ev.preventDefault();
          onClickBoard(boardID);
        }}>
        <div style={{ height: '144px', display: 'flex' }}>
          <CardMedia
            sx={{ height: '144px', width: '244px', margin: 'auto' }}
            image={coverImageUrl || boardPlaceholder}
            title={title}
          />
        </div>
        <CardContent
          sx={{
            flex: '1 0 auto',
            margin: '8px',
            padding: '0 !important',
            fontSize: '14px',
            whiteSpace: 'break-spaces',
          }}
          style={{
            color: 'white',
            lineClamp: 3,
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
          }}>
          {title}
        </CardContent>
      </Card>
    </div>
  );
};

MyDayRecipeCollectionCard.propTypes = {
  boardID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string.isRequired,
  onClickBoard: PropTypes.string.isRequired,
  isFavourite: PropTypes.bool.isRequired,
};

export default MyDayRecipeCollectionCard;
