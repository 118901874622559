import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material';
import { ReactElementLike } from 'prop-types';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import EditProgrammeForm from './edit_programme_form';
import { Programme } from '../../API';
import UnsavedChangesWarningPopover from '../common/unsaved_changes_warning_popover';

interface EditProgrammeModalProps {
  programmeId: string;
}

interface RootState {
  programmes: Array<Programme>;
}

const EditProgrammeModal = ({ programmeId }: EditProgrammeModalProps) => {
  const navigate = useNavigate();

  const [unsavedChangesWarningVisible, setUnsavedChangesWarningVisible] =
    useState(false);

  const [unsavedChangesWarningAnchorEl, setUnsavedChangesWarningAnchorEl] =
    useState(null as any as EventTarget | null);

  const onConfirmClose = () => {
    navigate(-1);
  };

  const onDialogClose = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    isClickAway: boolean,
  ) => {
    console.log({ isDirty });
    if (isDirty) {
      event.preventDefault();
      setUnsavedChangesWarningVisible(true);
      setUnsavedChangesWarningAnchorEl(isClickAway ? null : event.target);
    } else {
      onConfirmClose();
    }
  };

  const onDismissUnsavedChangesWarningPopover = () => {
    setUnsavedChangesWarningAnchorEl(null);
    setUnsavedChangesWarningVisible(false);
  };

  const theme = useTheme();

  const [isDirty, setIsDirty] = useState(false);

  const programme = useSelector((state: RootState) =>
    state.programmes.find((p) => p.id === programmeId),
  );

  if (!programme) {
    return null;
  }

  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        onDialogClose(event, true)
      }>
      <DialogTitle className="content">
        <Box
          sx={{
            marginTop: '-15px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}>
          <Tooltip title="Dismiss and return to board" placement="top-start">
            <IconButton
              aria-label="close"
              onClick={(event) => onDialogClose(event, false)}
              size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <div style={{ marginLeft: 10 }}>Edit program</div>
      </DialogTitle>
      <DialogContent>
        <EditProgrammeForm
          programme={programme}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
        />
      </DialogContent>
      {unsavedChangesWarningVisible && (
        <UnsavedChangesWarningPopover
          anchorEl={unsavedChangesWarningAnchorEl as any as ReactElementLike}
          onConfirmClose={onConfirmClose}
          onDismiss={onDismissUnsavedChangesWarningPopover}
        />
      )}
    </Dialog>
  );
};

export default EditProgrammeModal;
